import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';


@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  constructor() {



  }

  async open_and_wait(url) {
    return new Promise((resolve) => {
      Browser.open({ url: url }).then( () =>{
        console.log("----ireki du------------------")
        Browser.addListener( 'browserFinished', () => {
          console.log('Browser Finished Listen Result');
          resolve(true)
        });
      });
    });
  }

  async open(url) {
    await Browser.open({ url: url });



    //addListener(eventName: 'browserFinished', listenerFunc: () => void) => Promise<PluginListenerHandle> & PluginListenerHandle

  };


}






