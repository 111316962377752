import { Injectable } from '@angular/core';
import { Observable, of, from, defer } from 'rxjs';
import { catchError, tap,switchMap, map } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ActionSheetController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';

import { Platform } from '@ionic/angular';
//import { File } from '@ionic-native/file/ngx';
import {  Filesystem, Directory, Encoding } from '@capacitor/filesystem';

import { HttpService } from './http.service';
import { Media } from '@capacitor-community/media';

//import {IonicUnzip} from 'angular-ionic-unzip';
//import { Zip } from 'capacitor-zip';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';

import { GiappService } from './giapp.service';
import { StorageService } from './storage.service';




@Injectable({
  providedIn: 'root'
})
export class GiappExtraService {

  //fileTransfer: FileTransferObject = null;
  duration_toast:number=this.define_duration_toast();
  
  egokitzapena_default_save_url="https://cloud.tokimedia.eus/public/giapp/rest-api/egokitzapena/default-save";
  gustoko_filtroak_save_url="https://cloud.tokimedia.eus/public/giapp/rest-api/giapp-user-push/gustoko-filtroak-save";
  gustoko_filtroak_load_url="https://cloud.tokimedia.eus/public/giapp/rest-api/giapp-user-push/gustoko-filtroak-load";
  kontua_sortu_load_url="https://cloud.tokimedia.eus/public/giapp/rest-api/kontua-sortu/load";
  gida_abantailak_zerrenda_url="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/gida/abantailak/zerrenda";
  gida_abantaila_load_url="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/gida/abantaila/load";
  zozketan_parte_hartu_load_url="https://cloud.tokimedia.eus/public/giapp/rest-api/zozketa/parte-hartu-load";
  fitxaren_argazki_zerrenda_url="https://cloud.tokimedia.eus/public/giapp/rest-api/fitxa/argazki-zerrenda";
  gehitu_eskaintza_save_url="https://cloud.tokimedia.eus/public/giapp/rest-api/simulatu-gida-gehitu-eskaintza";

  filesystem=null;

  album_name="Goiena";
  album_identifier=null;
  
  constructor(
  	public giappService: GiappService,
  	private http: HttpClient,
    private storage: StorageService,
    private previewAnyFile: PreviewAnyFile,
    public actionSheetSocialSharingController: ActionSheetController,
    //public navCtrl: NavController,
    //private transfer: FileTransfer,
    //private file: File,
    public toastController: ToastController,
    private nativeHTTP: HttpService,
    //private fileSystem: FilesystemPlugin,
    //private androidPermissions: AndroidPermissions,
    private platform: Platform,
    //private unzipService:IonicUnzip,
    //private media: Media,
    private httpNative: HttpService,
  ) {
    
  }

  async remoteEgokitzapenaDefaultSave(){
  	const val=await this.storage.getObject("giapp_user")
    //.then((val) => {
      let giapp_user=val;
      this.service_egokitzapena_default_save(giapp_user);
    //});
  }

  service_egokitzapena_default_save(giapp_user){
  		let user_email=this.giappService.get_row_field_value(giapp_user,"user_email",false);
      let user_api_id=this.giappService.get_row_field_value(giapp_user,"user_api_id",true);

      let giapp_hedabideak_id:number=0;
	    let current_gvars=this.giappService.getGlobalVars();
	    giapp_hedabideak_id=current_gvars.giapp_hedabideak_id;

	    let egokitzapena_send={"giapp_hedabideak_id":giapp_hedabideak_id,"user_email":user_email,"user_api_id":user_api_id};
      egokitzapena_send["giapp_user"]=giapp_user;
	    let egokitzapena_send_row=JSON.stringify(egokitzapena_send);
    
      let egokitzapena_post_data=JSON.stringify({"egokitzapena":egokitzapena_send_row});
      this.egokitzapenaDefaultSave(egokitzapena_post_data).subscribe((r)=>{
        //console.log("egokitzapenaSave service: " + JSON.stringify(r))
      });
  }

  egokitzapenaDefaultSave(egokitzapena_post_data,custom_access_token_in=""): Observable<any> {
    let httpOptions=this.define_httpOptions(custom_access_token_in);
    return this.http.post(this.set_remote_url(this.egokitzapena_default_save_url), egokitzapena_post_data, httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ this.egokitzapena_default_save_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    
  	return this.giappService.handleError(operation, result);

  }

  set_remote_url(url_in){

  	return this.giappService.set_remote_url(url_in);

  }

  define_httpOptions(custom_access_token_in,enctype_in='',custom_token_type_in=''){

  	return this.giappService.define_httpOptions(custom_access_token_in,enctype_in,custom_token_type_in);

  }

  is_profila_rol_kluba(current_profila){

    if(current_profila["rol"]!=null && current_profila["rol"].length>0 && current_profila["rol"]=="kluba"){

      return true;

    }

    return false;
  }

  gustokoFiltroaSave(post_data,custom_access_token_in=""): Observable<any> {

    //let httpOptions=this.httpHeaderPost;
    let httpOptions=this.define_httpOptions(custom_access_token_in);
    
    return this.http.post(this.set_remote_url(this.gustoko_filtroak_save_url), post_data, httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ this.gustoko_filtroak_save_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  badauka_lat_lon(gida_negozioa_in){

    let lat_lon_array=this.get_lat_lon_array(gida_negozioa_in);
    let num=lat_lon_array.length;

      if(num>1){

        return true;

      }


    return false;
  }

  get_lat_lon_array(gida_negozioa_in){

    let lat_lon_array=[];

    if(gida_negozioa_in["get_lat_lon"]!=null && gida_negozioa_in["get_lat_lon"].length>0){

      lat_lon_array=gida_negozioa_in["get_lat_lon"].split(";");
     

    }


    return lat_lon_array;

  }

  negozioa_has_page(gida_negozioa){

    if(gida_negozioa!=null && gida_negozioa["has_page"]!=null && gida_negozioa["has_page"]){
      //console.log("negozioa_has_page="+gida_negozioa["has_page"]);
      return 1;

    }

    return 0;
  }

  negozioak_badauka_ordutegia(negozioa_row){

    //simulatzeko
    //return 1;

    if(this.negozioa_has_page(negozioa_row)){

      //console.log("negozioak_badauka_ordutegianegozioak_badauka_ordutegia has_page");

      if(negozioa_row!=null){

        if(negozioa_row["badauka_ordutegia"]!=null && negozioa_row["badauka_ordutegia"]==1){

            //OHARRA::::Beharrezkoa bada baldintza aktibatu atzera
            //if(negozioa_row["custom_ordutegia"]!=null && negozioa_row["custom_ordutegia"].length>0){

              return 1;

            //}

        }

      }

    }        

    return 0;
  }

  get_negozioa_irekita_class(negozioa_row){

    let irekita_class="";

    //console.log("get_negozioa_irekita_class");

    if(this.negozioak_badauka_ordutegia(negozioa_row)){
      //console.log("negozioak_badauka_ordutegia");
      
      if(this.is_irekita_by_ordutegia(negozioa_row)){

        irekita_class=" custom-open";

      }else{

        irekita_class=" custom-closed";

      }

    }


    return irekita_class;
  }

  is_irekita_by_ordutegia(negozioa_row){

    if(negozioa_row!=null && negozioa_row["custom_irekita_by_ordutegia"]!=null && negozioa_row["custom_irekita_by_ordutegia"]==1){

      return 1;

    }

    return 0;
  }

  get_negozioa_irekita_label(negozioa_row){

    let irekita_label="";

    if(this.negozioak_badauka_ordutegia(negozioa_row)){

      if(this.is_irekita_by_ordutegia(negozioa_row)){

        irekita_label="Orain irekita";

      }else{

        irekita_label="Orain itxita";

      }

    }


    return irekita_label;
  }

  is_show_zerrendan_gida_negozio_ordutegia(negozioa_row){

    if(this.negozioa_has_page(negozioa_row)){
      //console.log("badauka has_page");
      
      if(this.negozioak_badauka_ordutegia(negozioa_row)==1){
        //console.log("negozioak_badauka_ordutegia");
        
        return 1;    

      }  

    }

    return 0;
  }

  print_row(row){

    let field="";

    for(field in row){

      console.log(field+"="+row[field]);

    }

  }

  is_empty_filtroa(filtroa){

    //this.print_row(filtroa);

    if(filtroa==null){

      return true;

    }

    let num_fields=this.get_row_num_fields(filtroa);

    if(num_fields==2){

      if(filtroa['bilatu_testua']!=null && filtroa['bilatu_testua'].length==0){

        if(filtroa['bilatu_type']!=null && filtroa['bilatu_type']=='komertzioak'){

          return true;

        }

      }

    }

    return false;
  }

  get_row_num_fields(row){

    let field="";
    let kont=0;

    for(field in row){

      //console.log(field+"="+row[field]);

      kont=kont+1;
    }

    return kont;
  }

  async reset_media_filtroa(profila){

    let gordetako_probintziak_field_name:string=this.giappService.get_storage_field_name_by_profila(profila,"gordetako_probintziak");
    let current_gordetako_probintziak=[];
    await this.storage.setObject(gordetako_probintziak_field_name,current_gordetako_probintziak);

    let gordetako_herriak_field_name:string=this.giappService.get_storage_field_name_by_profila(profila,"gordetako_herriak");
    let current_gordetako_herriak=[];
    await this.storage.setObject(gordetako_herriak_field_name,current_gordetako_herriak);

    let gordetako_gaiak_field_name:string=this.giappService.get_storage_field_name_by_profila(profila,"gordetako_gaiak");
    let current_gordetako_gaiak=[];
    await this.storage.setObject(gordetako_gaiak_field_name,current_gordetako_gaiak);

    let gordetako_filtroa_field_name:string=this.giappService.get_storage_field_name_by_profila(profila,"gordetako_filtroa");
    let current_filtroa={};
    await this.storage.setObject(gordetako_filtroa_field_name,current_filtroa);


  }

  gustokoFiltroakLoad(post_data): Observable<any> {

    let httpOptions=this.define_httpOptions("");
    
    return this.http.post(this.set_remote_url(this.gustoko_filtroak_load_url), post_data, httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ this.gustoko_filtroak_load_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  async set_storage_is_fitxa_median_bilatu(is_fitxa_median_bilatu){
    if(is_fitxa_median_bilatu==0){
      const val=await this.storage.getObject("giapp_user")
      //.then((val) => {
          let giapp_user=val;
          this.reset_media_filtroa(giapp_user);
      //});
    }  

    await this.storage.setObject('is_fitxa_median_bilatu',is_fitxa_median_bilatu);

  }

  on_tab_set_storage_is_fitxa_median_bilatu(tab_media_name,tab_in){

    //OHARRA::::Kasu honetan bakarra desaktibatu behar da bilaketa
    if(tab_media_name==tab_in){

      let is_fitxa_median_bilatu=0;

      this.set_storage_is_fitxa_median_bilatu(is_fitxa_median_bilatu);  

    }

  }

  async set_storage_hasierako_user_type(hasierako_user_type){

    await this.storage.setObject("hasierako_user_type",hasierako_user_type);

  }

  required_validate(target_class,required_fields){

    let num:number=required_fields.length;
    let i:number=0;
    let field:string="";
    let value:string="";
    let is_validate=true;
    let field_validate_class="";

    //console.log("num="+num);
    
    if(num>0){

      for(i=0;i<num;i++){

        field=required_fields[i];
        field_validate_class=field+"_validate_class";

        if(target_class[field]==null){
          is_validate=false;
          target_class[field_validate_class]=" custom-required";
          continue;
        }

          value=target_class[field];

          //console.log("value_hasiera="+value+"=value_end");

          value=value.trim();

          //console.log("trim value_hasiera="+value+"=value_end");

          if(value.length==0){

             target_class[field_validate_class]=" custom-required";

             is_validate=false;

          }
        

      }

    }

    return is_validate;
  }

  prepare_profila(r_in){

    let r=r_in;

    if(r.count>0 && r.results!=null){

      if(r.results[0]["user_api_id"]==null){

        if(r.results[0]["id"]!=null){
          //console.log("prepare_profila");
          r.results[0]["user_api_id"]=r.results[0]["id"];
        }

      }

    }  

    return r;
  }

  email_validate(emaila){
    var pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(!pattern.test(emaila)) {
      return false;
    }

    return true;
  }

  pasahitza_berretsi_validate(pasahitza,pasahitza_berretsi){

    if(pasahitza!=null && pasahitza_berretsi!=null){

      if(pasahitza.length>0 && pasahitza_berretsi.length>0){

        if(pasahitza==pasahitza_berretsi){
          return true;
        }

      }

    }

    return false;
  }

  is_token_auth_success(r){
    
    if(r!=null){

      if(r.token!=null){

        if(r.token.length>0){
          return true;
        }

      }

    }

    return false;  
  }

  async set_storage_is_kontua_sortzeko_datuak_beteak(is_kontua_sortzeko_datuak_beteak){
    //console.log("@@@--++--Gipaa extraaa--set_storage_is_kontua_sortzeko_datuak_beteak " + JSON.stringify(is_kontua_sortzeko_datuak_beteak))
    await this.storage.setObject("is_kontua_sortzeko_datuak_beteak",is_kontua_sortzeko_datuak_beteak);
  }

  is_bazkidea_by_profila(profila){
    if(profila!=null){
      if(profila['is_bazkidea']!=null){
        if(profila['is_bazkidea']==1){
          return 1;
        }
      }
    }
    return 0;
  }

  kontuaSortuLoad(post_data): Observable<any> {

    let httpOptions=this.define_httpOptions("");
    
    return this.http.post(this.set_remote_url(this.kontua_sortu_load_url), post_data, httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ this.kontua_sortu_load_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  get_is_bazkidea_by_kontua_sortu_row(r){

    let is_bazkidea=0;

    if(r!=null){

          //console.log("kontuaSortuLoad="+JSON.stringify(r));

          if(r.kontua_sortu_row!=null){

            if(r.kontua_sortu_row.is_bazkidea!=null){

              is_bazkidea=r.kontua_sortu_row.is_bazkidea;

            }

          }

    }

    return is_bazkidea;

  }

  define_ez_zara_klubeko_kide_zozketak_text(){

    let result="";
    
    result="Zozketa hau soilik Goienakideentzako da. Goienakide bazara eta ezin baduzu parte hartu, jarri gurekin harremanetan: goienakide@goiena.eus";

    return result;
 
  }

  webguneZozketaApuntatu(post_data,custom_access_token_in=""): Observable<any> {

    let httpOptions=this.define_httpOptions(custom_access_token_in);
    //let httpOptions=this.define_httpOptions(custom_access_token_in,"","token_barra_t","webguneZozketaApuntatu");
    //let httpOptions=this.define_httpOptions(custom_access_token_in,"","","webguneZozketaApuntatu");

    let current_zozketa_apuntatu_url=this.giappService.get_url_webgune_api('zozketa_apuntatu');

    //console.log("webguneZozketaApuntatu post_data="+JSON.stringify(post_data));
    //console.log("webguneZozketaApuntatu custom_access_token_in="+custom_access_token_in);

    //console.log("current_zozketa_apuntatu_url="+current_zozketa_apuntatu_url);

    //console.log("webguneZozketaApuntatu  header: " + JSON.stringify(httpOptions));

    return this.httpNative.post(current_zozketa_apuntatu_url, post_data, httpOptions ).pipe(
      map(res => { 
        //console.log("=====webguneZozketaApuntatu======");
        //console.log("webguneZozketaApuntatu: " + JSON.stringify(res) )
        //console.log("webguneZozketaApuntatu res.data: " + res.data )
        return res.data;
      }),
      tap(_ => console.log('webguneZozketaApuntatu fetched!')),
      catchError(this.handleError<any>('POST webguneZozketaApuntatu', []))
    );

  }

  getGidaAbantailak(post_data,custom_access_token_in=""): Observable<any> {

    let httpOptions=this.define_httpOptions("");
    
    let current_gida_abantailak_zerrenda_url:string=this.set_remote_url(this.gida_abantailak_zerrenda_url)

    return this.http.post(current_gida_abantailak_zerrenda_url, post_data, httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+current_gida_abantailak_zerrenda_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  get_abantaila_title(abantaila_row){

    return this.giappService.get_negozioa_title(abantaila_row);

  }

  get_abantaila_negozioa_title(abantaila_row){
    
    let negozioa_title=this.giappService.get_row_field_value(abantaila_row,"negozioa_title");

    negozioa_title=negozioa_title.toUpperCase();

    return negozioa_title;
  }

  loadGidaAbantaila(post_data,custom_access_token_in=""): Observable<any> {
    let httpOptions=this.define_httpOptions("");
    let current_gida_abantailak_load_url:string=this.set_remote_url(this.gida_abantaila_load_url);
    return this.http.post(current_gida_abantailak_load_url, post_data, httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+current_gida_abantailak_load_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  is_zozketa_epean(zozketa,type='',profila_in=null){
    let current_date=new Date();
    let current_time=current_date.getTime();
    
    let end_date=new Date(zozketa.end_date);
    let end_time=end_date.getTime();
    
    if(current_time<=end_time){
      
      if(type=='parte_hartua'){
       
        if(this.is_apuntatua_zozketan_by_parte_hartzaileak(profila_in,zozketa)){
          
          return 1;
        }
      
        return 0;
      }

      return 1;
    }
    return 0;
  }

  is_zozketa_epean_by_rol(zozketa,rol,zozketa_parte_hartu_row){
    if(this.is_zozketa_epean(zozketa)){
      if(this.is_apuntatua_lehendik_zozketan(zozketa_parte_hartu_row)){
        return 0;
      }
      if(rol=="kluba"){
        return 1;
      }
    }
    return 0;
  }

  loadZozketaParteHartu(post_data){
    let httpOptions=this.define_httpOptions("");
    let current_zozketan_parte_hartu_load_url:string=this.set_remote_url(this.zozketan_parte_hartu_load_url);

    return this.http.post(current_zozketan_parte_hartu_load_url, post_data, httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+current_zozketan_parte_hartu_load_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );

  }

  is_apuntatua_lehendik_zozketan(zozketa_parte_hartu_row){
    if(zozketa_parte_hartu_row!=null){
      if(zozketa_parte_hartu_row["id"]!=null){
        let id:number=zozketa_parte_hartu_row["id"];
        if(id>0){
          return 1;
        }
      }      
    }
    return 0;
  }

  shareAPP(app_hau_partekatu_url){
    this.giappService.shareMsg("Goiena APP", "Goienako APP-a\n",app_hau_partekatu_url)
  }

  define_iab_options(){
    let options="";
    let options_array=[];

    //location=no,footer=yes,closebuttoncaption=Done,closebuttoncolor=#0000ff

    options_array.push("location=yes");
    options_array.push("footer=yes");
    options_array.push("closebuttoncaption=ITXI");
    options_array.push("closebuttoncolor=#ffffff");

    options=options_array.join(",");

    return options;
  }

  download_folder(){
    let dirPath1=Directory.Data;
    let dirPath2=Directory.Documents;
    
    return new Promise<any>( (resultdirPath)=>{
      Filesystem.requestPermissions().then( (permission) =>{
        if (permission.publicStorage="granted") {
          if ( this.platform.is('android')){
            try{
              resultdirPath({root: Directory.Data, path:""})
              /*Filesystem.stat({path: "/../Download" ,directory: dirPath2 }).then((stat_r)=>{              
                Filesystem.stat({path: "/../Download/goiena" ,directory: dirPath2 }).then((stat_r)=>{
                  resultdirPath ( {root: dirPath2, path: "/../Download/goiena"})
                }).catch((r)=>{
                  Filesystem.mkdir({path: "/../Download/goiena" ,directory: dirPath2 }).then( ()=>{
                    resultdirPath ( {root: dirPath2, path: "/../Download/goiena"})
                  }).catch( (re3)=>{
                    resultdirPath({root: dirPath1, path:""})
                  })
                })     
              })*/
            }catch(error){
              resultdirPath({root: dirPath1, path:""})
            }
          }else if ( this.platform.is('ios')){
            resultdirPath({root: Directory.Data, path:""})
          }else{
            resultdirPath({root: "/tmp", path:""})
          }
        }
      }).catch( (er0)=>{
        resultdirPath({root: dirPath1, path:""})
      })
    });
  }

  create_album(){
    Media.getAlbums().then( (album_list ) =>{
      album_list['albums'].forEach( (album_exist,  index, alist) => {
        if (album_exist['name'] == this.album_name ){
          this.album_identifier=this.platform.is('ios') ? album_exist.identifier : album_exist.name;
          return true;
        }
        if (index >= alist.length -1 ){
          //this.album_identifier=this.album_name
          Media.createAlbum({name:this.album_name}).then( ()=>{
            if (!this.album_identifier) {
              this.create_album()
            }
            return true;
          }).catch((error)=>{
            this.album_identifier=this.album_name
            console.log("Error create album:: " + this.album_name )
            console.log("Error create album:: " + error )
            return false;
          })
        }
      })
    }).catch(()=>{
      console.log("List album error")
      return false
    });
  }


  media_file(media_files:any, fdir=null){
    if ( media_files.length == 0 || media_files[0]==""){
      console.log("Ez da jaso ezer ez!!!")
      return 1
    } 
    media_files.forEach( media_file => {

      //console.log("media_files sartu da");

      //intelsat-2023
      //OHARRA::::Hau produkzioan aktibatua eduki behar da, orain intelsat ordenadorean album ez duenez ezagutzen komentatua dago zati hau guztia
      if (media_file){
        let abs_media_file:string=media_file
        if (fdir) abs_media_file=fdir +"/"+media_file
        if (abs_media_file.endsWith(".mp4") ){
          if ( this.platform.is('ios')){
            Media.saveVideo({path:abs_media_file, album: { id: this.album_identifier, name: this.album_name } }).then(()=>{
              Filesystem.deleteFile({"path": abs_media_file, "directory": null })
            }).catch((rrerror)=>{
              console.log("ERROR Save video: " + JSON.stringify(rrerror))
            })
          }else{
            Media.saveDocument({path:abs_media_file, album: { id: this.album_identifier, name: this.album_name }} ).then(()=>{
              Filesystem.deleteFile({"path": abs_media_file, "directory": null })
            }).catch((rrerror)=>{
              console.log("ERROR Save document: " + JSON.stringify(rrerror))
            })
          }
        }else if( 
          abs_media_file.endsWith(".jpg") || 
          abs_media_file.endsWith(".png") || 
          abs_media_file.endsWith(".jpeg") ||
          abs_media_file.endsWith(".JPEG") || 
          abs_media_file.endsWith(".JPG") 
        ){
          Media.savePhoto({path:abs_media_file, album: { id: this.album_identifier, name: this.album_name}} ).then(()=>{
            Filesystem.deleteFile({"path": abs_media_file, "directory": null })
          }).catch((rrerror)=>{
            console.log("ERROR Save photo: " + JSON.stringify(rrerror))
          })
        }else{
          Media.saveDocument({path:abs_media_file,album: { id: this.album_identifier, name: this.album_name}}).then(()=>{
            Filesystem.deleteFile({"path": abs_media_file, "directory": null })
          }).catch((rrerror)=>{
            console.log("ERROR Save document: " + JSON.stringify(rrerror))
          })
        }
      }
    });
    
  }

  
  download_file_url(download_url_in, bideo=null, open=false,rol_in="" ){
    this.create_album();
    if(bideo!=null){
      if(bideo["fitxa_mota_name"]!=null){
        if(bideo["fitxa_mota_name"]=="image"){
          this.service_get_fitxaren_argazki_zerrenda(bideo,open,rol_in);
          return 1;
        }
      }
    }
    return this.jarraitu_download_file_url(download_url_in,bideo,open, true );
  }  

  //intelsat-2021-giapp-capacitor
  jarraitu_download_file_url(download_url_in, bideo=null, open=false, toast=true ){ 
    if(open){
      this.previewAnyFile.preview( download_url_in )
        .then((res: any) => console.log(res))
        .catch((error: any) => console.error(error));
    }
    
    let download_url=encodeURI(download_url_in);
    let basename= download_url_in.substring(download_url_in.lastIndexOf('/')+1);
    if (bideo){
      basename=this.get_basename_by_download(bideo);
    }
    if (toast && ! open ){
      this.presentToast( " deskarga hasi da.");
    }else if(toast){
      this.presentToast( " deskarga hasi eta berehala irekiko da.");
    }
    this.download_folder().then( (dirPath)=>{
      let fname=basename;
      if (dirPath.path) fname=dirPath.path + "/" +  basename

      Filesystem.requestPermissions().then( (permission) =>{
        if (permission.publicStorage="granted") {
          this.nativeHTTP.downloadFile( download_url_in, fname, dirPath.root ).pipe(
            map(res => { 
              return res
            }),
            tap( _ => console.log("deskarga jaso da") ),
            catchError(this.handleError<any>('Deskarga errorea ', []))
          ).subscribe( dfile => {
            if (toast && ! open ){
              this.presentToast( " deskarga bukatu da.");
            }
            this.media_file( [dfile.path] )
          });
        };
      })
    })   
  }

  get_basename_by_download(bideo){

    let basename="";

    //basename="GOI_"+bideo.id+".mp4";

    if(bideo["download_basename"]!=null){

      basename=bideo["download_basename"];
    }

    return basename;
  }

  async presentToast(msg_in) {

    let msg:string=msg_in;

    
      /*let current_datetime=this.get_current_datetime_format();
      msg=msg_in+" "+current_datetime;*/

      
      let current_toast = await this.toastController.create({
        message: msg,
        duration: this.duration_toast,
        position: 'top',
      });

      current_toast.onDidDismiss()
        .then((data) => {
          ////console.log("onDidDismiss event");
      });

      current_toast.present();

               
  }
  define_duration_toast(){

    let current_duration_toast:number=2000;

    //current_duration_toast=10000;
    current_duration_toast=5000;

    return current_duration_toast;
  }

  //intelsat-2021-giapp-capacitor
  abisatzekoFiltroakLoad(post_data): Observable<any> {

    return this.gustokoFiltroakLoad(post_data);
  }

  //intelsat-2021-giapp-capacitor
  get_abisatzekoak_row(r){

    if(r["gustokoak_row"]!=null){

      return r["gustokoak_row"];
    }

    return null;
  }

  //intelsat-2021-giapp-capacitor
  service_get_fitxaren_argazki_zerrenda(bideo,open,rol_in){
      let giapp_hedabideak_id:number=0;
      let sareko_id:string="";
      let current_gvars=this.giappService.getGlobalVars();
      let ur_markarekin=1;
      giapp_hedabideak_id=current_gvars.giapp_hedabideak_id;
      sareko_id=current_gvars.sareko_id;
      ur_markarekin=this.get_ur_markarekin_int_value(rol_in);

      let post_data={"giapp_hedabideak_id":giapp_hedabideak_id,"sareko_id":sareko_id,"nid":bideo.id,"ur_markarekin":ur_markarekin};

      //console.log("post_data="+JSON.stringify(post_data));
      //return 1;
      
      this.getFitxarenArgazkiZerrenda(post_data).subscribe((r)=>{
        this.on_get_fitxaren_argazki_zerrenda(bideo,open,r);        
      });

  }

  //intelsat-2021-giapp-capacitor
  getFitxarenArgazkiZerrenda(post_data){
    let httpOptions=this.define_httpOptions("");
    return this.http.post(this.set_remote_url(this.fitxaren_argazki_zerrenda_url), post_data, httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ this.gustoko_filtroak_save_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  //intelsat-2021-giapp-capacitor
  on_get_fitxaren_argazki_zerrenda(bideo,open,r){
    if(r!=null){
      if(r["fitxaren_argazki_zerrenda"]!=null){
        let fitxaren_argazki_zerrenda=r["fitxaren_argazki_zerrenda"];
        let num=fitxaren_argazki_zerrenda.length;
        let i=0;
        let image_src="";

        /*console.log("on_get_fitxaren_argazki_zerrenda r="+JSON.stringify(r));
        return 1;*/
        
        if(num>0){
          for(i=0;i<num;i++){
            if (i==0 ){
              this.presentToast( " deskarga hasi da.");
            } 
            image_src=fitxaren_argazki_zerrenda[i].src;
            this.jarraitu_download_file_url(image_src,false,false, false );
            if (i == num-1 ){
              this.presentToast( " deskarga bukatu da.");
            } 
          }
        }
      }
    }
  }

  //intelsat-2021-giapp-capacitor
  get_ur_markarekin_int_value(rol_in){

    //OHARRA::::ur_markarekin=2, ur marka kentzeko da, defektuz ur markarekin jaitsiko baititugu

    if(rol_in=="kluba"){

      return 2;
    }

    return 1;
  }

  //intelsat-2021-giapp-capacitor
  get_nire_egoera_zozketan_label(profila,zozketa,label_in,is_amaituta){

    //console.log("get_nire_egoera_zozketan_label profila="+JSON.stringify(profila));

    let label:string="";

    if(!is_amaituta){

        if(this.is_apuntatua_zozketan_by_parte_hartzaileak(profila,zozketa)){

          label="Parte hartua";

          return label;
        }

      
    }  

    return label_in;

  }

  is_apuntatua_zozketan_by_parte_hartzaileak(profila,zozketa){
  
      let is_numeric=true;
      let user_api_id=this.giappService.get_row_field_value(profila,"id",is_numeric);
      
      if(user_api_id>0){
        
        //console.log("user_api_id="+user_api_id);
        //console.log("zozketa="+JSON.stringify(zozketa));

        if(this.giappService.in_array({user_api_id:user_api_id},"user_api_id",zozketa.parte_hartzaileak_array)){

          return 1;
        }

      }

      return 0;
  }

  //intelsat-2023
  badauka_zozketak_irabazlerik(zozketa){

    if(zozketa["irabazleak"]!=null){

      if(zozketa.irabazleak.length>0){

        return 1;
      }
    }

    return 0;
  }

  gehituEskaintzaSave(post_data,custom_access_token_in,custom_token_type_in="",argazkia_file_in=null){
    let argazkia_file=argazkia_file_in;
    let httpOptions={};

    //console.log("argazkia_file="+argazkia_file);

    //intelsat-2021-giapp-capacitor
    httpOptions=this.define_httpOptions(custom_access_token_in,'multipart/form-data;',custom_token_type_in);

    let url:string=this.set_remote_url(this.gehitu_eskaintza_save_url)
    let form_data = new FormData();
    form_data.append('file', argazkia_file);

    form_data.append("izena",post_data.izena);
    form_data.append("sareko_id",post_data.sareko_id);
    form_data.append("giapp_hedabideak_id",post_data.giapp_hedabideak_id);
    form_data.append("deskribapena",post_data.deskribapena);
    form_data.append("kanpaina",post_data.kanpaina);
    form_data.append("negozioa_json",post_data.negozioa_json);

    //console.log("form_data="+form_data.get('file'));

    //intelsat-2021-giapp-capacitor
    return this.httpNative.post(url, form_data, httpOptions,'multipart/form-data;' ).pipe(
      map(res => { 
    
        return res.data
      }),
      tap(_ => console.log('gehituEskaintzaSave fetched!')),
      catchError(this.handleError<any>('userPhotoUpload error', []))
    );

  }                  
}  