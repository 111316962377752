import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';



@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  async setORG(key: string, value: string|any[]|object ) {

    if ( typeof(value) != 'string' ){
      console.log("------stringnify--------------")
      value=JSON.stringify(value)
    }
    console.log("SET storage key: " + key + "  type.value " + typeof(value) +"  val: " +  value )
    await Storage.set({ key:key, value: value });
  }

  async set(key: string, value: string|any[]|object  ) {
    if ( typeof(value) != 'string' ){
      value=JSON.stringify(value)
    }
    console.log("SET storage key: " + key + "  type.value " + typeof(value) +"  val: " +  value )
    await Storage.set({ key:key, value: value });
    return true 
  }

  async getORG(key: string) {
    const value=String(await Storage.get({ key: key }))
    
    //intelsat-2021-giapp-capacitor
    //OHARRA::::Bestela userraren tokena kontsolan erakusten da
    if(key!="custom_access_token"){

      console.log("GET storage key: " + key + "  type.value " + typeof(value) +"  val: " +  value )
    }

    return value;
  }

  async get(key: string) {
    const value=await Storage.get({ key: key })
    
    //intelsat-2021-giapp-capacitor
    //OHARRA::::Bestela userraren tokena kontsolan erakusten da
    //if(key!="custom_access_token"){
    if(this.is_print_console_log(key)){

      console.log("GET storage key: " + key + "  type.value " + typeof(value.value) +"  val: " +  value.value );
    }
    
    if ( value.value && ( value.value.charAt(0)=='{' || value.value.charAt(0)=='[' )  ){
      return JSON.parse(value.value)
    }else{

      //intelsat-2021-giapp-capacitor
      /*OHARRA::::Storage-an adibidez giapp_rol "arrunta" bezela dago, baino komilla horiek arazoak sor ditzakete, arazoa sortzen badute, funtzio hau
      erabili daiteke*/
      if(key=="giapp_rol"){
        return this.prepare_storage_string(value.value);
      }

      return value.value;
    }
  }

  async setObject(key: string, value: any) {

    if(this.is_print_console_log(key)){
      console.log("SET OBJ storage key: " + key + "  type.value " + typeof(value) +"  val: " +  JSON.stringify(value) );
    }

    await Storage.set({ key, value: JSON.stringify(value) });
  }

  async getObject(key: string) {
    const value=await Storage.get({ key: key })

    //intelsat-2021-giapp-capacitor
    //OHARRA::::Bestela userraren tokena kontsolan erakusten da

    if(this.is_print_console_log(key)){

        console.log("GET storagetObject key: " + key +"  val: " +  value.value )
    }

    return JSON.parse(value.value)

  }

  async remove(key: string) {
      await Storage.remove({ key });
  }

  async clear() {
      await Storage.clear();
  }

  async keys(){
    /*let ll=await Storage.keys().then( (z)=>{
      return z
    });*/
    let ll2=await Storage.keys()
    //console.log("LISTA::: " + JSON.stringify(ll))
    return ll2
  }
  
  //intelsat-2021-giapp-capacitor
  prepare_storage_string(string_in){

    let result=string_in.replaceAll(/['"]+/g, '');

    return result;
  }

  //intelsat-2021-giapp-capacitor
  //OHARRA::::Bestela userraren tokena kontsolan erakusten da
  private is_print_console_log(key){

    //if(key!="custom_access_token"){
    if(key!="custom_access_token" && key!="giapp_user" && key!="X-Auth-Token"){

      return true;
    }

    return false;
  }
}