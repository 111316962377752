import { Component, OnInit } from '@angular/core';
import { GiappService } from '../../core/giapp.service';
import {PopoverController} from '@ionic/angular';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-pop-zuzenekoa',
  templateUrl: './pop-zuzenekoa.page.html',
  styleUrls: ['./pop-zuzenekoa.page.scss'],
})
export class PopZuzenekoaPage implements OnInit {


  /****
  OHARRA::::Gerta daiteke zuzenekoa ez ikustea, Chromium-eko Developer Tools simuladorea aktibatua dagoenean, browser-ak dena
  okupatzen duenean, ondo doa
  *****/

  zuzenekoa:any={id:0,embed_kode_url:""};
  is_mugikorra_bertikalean_dago:Number=1;		

  constructor(
  	public giappService: GiappService,
  	private zuzenekoa_popover:PopoverController,
    public navParams:NavParams
  ) {
    this.zuzenekoa = this.navParams.get('zuzenekoa');
    console.log('zuzenekoa id modal='+this.zuzenekoa.id);
  
  }

  ngOnInit() {

    this.is_mugikorra_bertikalean_dago=this.giappService.is_mugikorra_bertikalean_dago();

  	//this.service_get_zuzenekoa();

  }


  ClosePopover()
   {
     this.zuzenekoa_popover.dismiss();
   }

  service_get_zuzenekoa(){

  	  let current_gvars=this.giappService.getGlobalVars();
      let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
      let row={"giapp_hedabideak_id":giapp_hedabideak_id};
      let zuzenekoa_row=JSON.stringify(row);
      let zuzenekoa_post_data=JSON.stringify({"zuzenekoa":zuzenekoa_row});

      //console.log('service_get_zuzenekoa giapp_hedabideak_id='+giapp_hedabideak_id);

      this.giappService.getZuzenekoa(zuzenekoa_post_data).subscribe((r)=>{
        
        this.zuzenekoa = r.zuzenekoa;

        //console.log('zuzenekoa id modal='+this.zuzenekoa.id);

        console.log('zuzenekoa embed_kode_url='+this.zuzenekoa.embed_kode_url);
        
      });

  }
  
  get_zuzenekoa_ion_grid_class(){

    let result="custom-zuzenekoa-modal-ion-grid";

    if(this.is_mugikorra_bertikalean_dago==0){

      result="";

    }

    return result;

  }

  get_zuzenekoa_ion_row_class(){

    let result="custom-zuzenekoa-modal-ion-row";

    if(this.is_mugikorra_bertikalean_dago==0){

      result="";

    }

    return result;

  }

  get_zuzenekoa_ion_col_class(){
  
    let result="custom-zuzenekoa-modal-ion-col embed-responsive embed-responsive-16by9";

    if(this.is_mugikorra_bertikalean_dago==0){

      //result="embed-responsive";
      result="";

    }

    return result;
  }  

}
