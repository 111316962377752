import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
 
const routes: Routes = [
  {
    path: 'm001',
    loadChildren: () => import('./m001/m001.module').then( m => m.M001PageModule)
  },
  {
    path: 'm002',
    loadChildren: () => import('./m002/m002.module').then( m => m.M002PageModule)
  },
  {
    path: 'm003',
    loadChildren: () => import('./m003/m003.module').then( m => m.M003PageModule)
  },
  {
    path: 'm004',
    loadChildren: () => import('./m004/m004.module').then( m => m.M004PageModule)
  },
  {
    path: 'tab-media',
    loadChildren: () => import('./tab-media/tab-media.module').then( m => m.TabMediaPageModule)
  },
  {
    path: 'tab-nahiera',
    loadChildren: () => import('./tab-nahiera/tab-nahiera.module').then( m => m.TabNahieraPageModule)
  },
  {
    path: 'tab-kluba',
    loadChildren: () => import('./tab-kluba/tab-kluba.module').then( m => m.TabKlubaPageModule)
  },
  {
    path: 'm005',
    loadChildren: () => import('./m005/m005.module').then( m => m.M005PageModule)
  },
  {
    path: 'tab-kontua',
    loadChildren: () => import('./tab-kontua/tab-kontua.module').then( m => m.TabKontuaPageModule)
  },
  {
    path: 'm006',
    loadChildren: () => import('./m006/m006.module').then( m => m.M006PageModule)
  },
  {
    path: 'm024',
    loadChildren: () => import('./m024/m024.module').then( m => m.M024PageModule)
  },
  {
    path: 'm027',
    loadChildren: () => import('./m027/m027.module').then( m => m.M027PageModule)
  },
  {
    path: 'popbideo',
    loadChildren: () => import('./popbideo/popbideo.module').then( m => m.PopbideoPageModule)
  },
  {
    path: 'm031',
    loadChildren: () => import('./m031/m031.module').then( m => m.M031PageModule)
  },
  {
    path: 'm032',
    loadChildren: () => import('./m032/m032.module').then( m => m.M032PageModule)
  },
  {
    path: 'm033',
    loadChildren: () => import('./m033/m033.module').then( m => m.M033PageModule)
  },
  {
    path: 'bideo-modal',
    loadChildren: () => import('./bideo-modal/bideo-modal.module').then( m => m.BideoModalPageModule)
  },
  {
    path: 'm030',
    loadChildren: () => import('./m030/m030.module').then( m => m.M030PageModule)
  },
  {
    path: 'm035',
    loadChildren: () => import('./m035/m035.module').then( m => m.M035PageModule)
  },
  {
    path: 'm039',
    loadChildren: () => import('./m039/m039.module').then( m => m.M039PageModule)
  },
  {
    path: 'm040',
    loadChildren: () => import('./m040/m040.module').then( m => m.M040PageModule)
  },
  {
    path: 'm041',
    loadChildren: () => import('./m041/m041.module').then( m => m.M041PageModule)
  },
  {
    path: 'confirm-logout',
    loadChildren: () => import('./confirm-logout/confirm-logout.module').then( m => m.ConfirmLogoutPageModule)
  },   {
    path: 'm058',
    loadChildren: () => import('./m058/m058.module').then( m => m.M058PageModule)
  },
  {
    path: 'm064',
    loadChildren: () => import('./m064/m064.module').then( m => m.M064PageModule)
  },
  {
    path: 'm066',
    loadChildren: () => import('./m066/m066.module').then( m => m.M066PageModule)
  },
  {
    path: 'm067',
    loadChildren: () => import('./m067/m067.module').then( m => m.M067PageModule)
  },
  {
    path: 'gaia-modal',
    loadChildren: () => import('./gaia-modal/gaia-modal.module').then( m => m.GaiaModalPageModule)
  },
  {
    path: 'herria-modal',
    loadChildren: () => import('./herria-modal/herria-modal.module').then( m => m.HerriaModalPageModule)
  },
  {
    path: 'm036',
    loadChildren: () => import('./m036/m036.module').then( m => m.M036PageModule)
  },
  {
    path: 'zuzenekoa-modal',
    loadChildren: () => import('./zuzenekoa-modal/zuzenekoa-modal.module').then( m => m.ZuzenekoaModalPageModule)
  },
  {
    path: 'pop-zuzenekoa',
    loadChildren: () => import('./pop-zuzenekoa/pop-zuzenekoa.module').then( m => m.PopZuzenekoaPageModule)
  },
  {
    path: 'm045',
    loadChildren: () => import('./m045/m045.module').then( m => m.M045PageModule)
  },
  {
    path: 'probintzia-modal',
    loadChildren: () => import('./probintzia-modal/probintzia-modal.module').then( m => m.ProbintziaModalPageModule)
  },
  {
    path: 'm037',
    loadChildren: () => import('./m037/m037.module').then( m => m.M037PageModule)
  },
  {
    path: 'm038',
    loadChildren: () => import('./m038/m038.module').then( m => m.M038PageModule)
  },
  {
    path: 'm038-gida-multzoak',
    loadChildren: () => import('./m038-gida-multzoak/m038-gida-multzoak.module').then( m => m.M038GidaMultzoakPageModule)
  },
  {
    path: 'm038-gida-fitxa-show',
    loadChildren: () => import('./m038-gida-fitxa-show/m038-gida-fitxa-show.module').then( m => m.M038GidaFitxaShowPageModule)
  },
  {
    path: 'm038-gida-komertzioak',
    loadChildren: () => import('./m038-gida-komertzioak/m038-gida-komertzioak.module').then( m => m.M038GidaKomertzioakPageModule)
  },
  {
    path: 'm038-gida-produktuak',
    loadChildren: () => import('./m038-gida-produktuak/m038-gida-produktuak.module').then( m => m.M038GidaProduktuakPageModule)
  },
  {
    path: 'm038-gida-saila-komertzioak',
    loadChildren: () => import('./m038-gida-saila-komertzioak/m038-gida-saila-komertzioak.module').then( m => m.M038GidaSailaKomertzioakPageModule)
  },
  {
    path: 'm038-gida-produktua-show',
    loadChildren: () => import('./m038-gida-produktua-show/m038-gida-produktua-show.module').then( m => m.M038GidaProduktuaShowPageModule)
  },
  {
    path: 'm038-gida-komertzioak-bilatu-form',
    loadChildren: () => import('./m038-gida-komertzioak-bilatu-form/m038-gida-komertzioak-bilatu-form.module').then( m => m.M038GidaKomertzioakBilatuFormPageModule)
  },
  {
    path: 'm038-gida-herria-modal',
    loadChildren: () => import('./m038-gida-herria-modal/m038-gida-herria-modal.module').then( m => m.M038GidaHerriaModalPageModule)
  },
  {
    path: 'm038-gida-saila-modal',
    loadChildren: () => import('./m038-gida-saila-modal/m038-gida-saila-modal.module').then( m => m.M038GidaSailaModalPageModule)
  },
  {
    path: 'm038-gida-azpi-saila-modal',
    loadChildren: () => import('./m038-gida-azpi-saila-modal/m038-gida-azpi-saila-modal.module').then( m => m.M038GidaAzpiSailaModalPageModule)
  },
  {
    path: 'txartel-digitala-modal',
    loadChildren: () => import('./txartel-digitala-modal/txartel-digitala-modal.module').then( m => m.TxartelDigitalaModalPageModule)
  },
  {
    path: 'm041-lege-oharrak',
    loadChildren: () => import('./m041-lege-oharrak/m041-lege-oharrak.module').then( m => m.M041LegeOharrakPageModule)
  },
  {
    path: 'm041-pribatutasun-politika',
    loadChildren: () => import('./m041-pribatutasun-politika/m041-pribatutasun-politika.module').then( m => m.M041PribatutasunPolitikaPageModule)
  },
  {
    path: 'argazkia-aldatu-modal',
    loadChildren: () => import('./argazkia-aldatu-modal/argazkia-aldatu-modal.module').then( m => m.ArgazkiaAldatuModalPageModule)
  },
  {
    path: 'txartel-arrunta-modal',
    loadChildren: () => import('./txartel-arrunta-modal/txartel-arrunta-modal.module').then( m => m.TxartelArruntaModalPageModule)
  },
  {
    path: 'user-probintzia-modal',
    loadChildren: () => import('./user-probintzia-modal/user-probintzia-modal.module').then( m => m.UserProbintziaModalPageModule)
  },
  {
    path: 'user-herria-modal',
    loadChildren: () => import('./user-herria-modal/user-herria-modal.module').then( m => m.UserHerriaModalPageModule)
  },
  {
    path: 'simulatu-user-push-form',
    loadChildren: () => import('./simulatu-user-push-form/simulatu-user-push-form.module').then( m => m.SimulatuUserPushFormPageModule)
  },
  {
    path: 'egin-kide-probintzia-modal',
    loadChildren: () => import('./egin-kide-probintzia-modal/egin-kide-probintzia-modal.module').then( m => m.EginKideProbintziaModalPageModule)
  },
  {
    path: 'egin-kide-herria-modal',
    loadChildren: () => import('./egin-kide-herria-modal/egin-kide-herria-modal.module').then( m => m.EginKideHerriaModalPageModule)
  },
  {
    path: 'gustoko-filtroak-form',
    loadChildren: () => import('./gustoko-filtroak-form/gustoko-filtroak-form.module').then( m => m.GustokoFiltroakFormPageModule)
  },
  {
    path: 'gustoko-probintzia-modal',
    loadChildren: () => import('./gustoko-probintzia-modal/gustoko-probintzia-modal.module').then( m => m.GustokoProbintziaModalPageModule)
  },
  {
    path: 'gustoko-herria-modal',
    loadChildren: () => import('./gustoko-herria-modal/gustoko-herria-modal.module').then( m => m.GustokoHerriaModalPageModule)
  },
  {
    path: 'gustoko-gaia-modal',
    loadChildren: () => import('./gustoko-gaia-modal/gustoko-gaia-modal.module').then( m => m.GustokoGaiaModalPageModule)
  },
  {
    path: 'm038-gida-abantailak',
    loadChildren: () => import('./m038-gida-abantailak/m038-gida-abantailak.module').then( m => m.M038GidaAbantailakPageModule)
  },
  {
    path: 'm038-gida-abantaila-show',
    loadChildren: () => import('./m038-gida-abantaila-show/m038-gida-abantaila-show.module').then( m => m.M038GidaAbantailaShowPageModule)
  },
  {
    path: 'qr-modal',
    loadChildren: () => import('./qr-modal/qr-modal.module').then( m => m.QrModalPageModule)
  },
  {
    path: 'aukeratutako-herrienak-form',
    loadChildren: () => import('./aukeratutako-herrienak-form/aukeratutako-herrienak-form.module').then( m => m.AukeratutakoHerrienakFormPageModule)
  },
  {
    path: 'abisatzeko-herriak-modal',
    loadChildren: () => import('./abisatzeko-herriak-modal/abisatzeko-herriak-modal.module').then( m => m.AbisatzekoHerriakModalPageModule)
  },
  {
    path: 'abisatzeko-probintzia-modal',
    loadChildren: () => import('./abisatzeko-probintzia-modal/abisatzeko-probintzia-modal.module').then( m => m.AbisatzekoProbintziaModalPageModule)
  },
  {
    path: 'abisatzeko-gaia-modal',
    loadChildren: () => import('./abisatzeko-gaia-modal/abisatzeko-gaia-modal.module').then( m => m.AbisatzekoGaiaModalPageModule)
  },
  {
    path: 'nire-negozioak',
    loadChildren: () => import('./nire-negozioak/nire-negozioak.module').then( m => m.NireNegozioakPageModule)
  },
  {
    path: 'push-modal',
    loadChildren: () => import('./push-modal/push-modal.module').then( m => m.PushModalPageModule)
  },
  {
    path: 'gida-iframe',
    loadChildren: () => import('./gida-iframe/gida-iframe.module').then( m => m.GidaIframePageModule)
  },
  {
    path: 'gida-iframe-fitxa-show',
    loadChildren: () => import('./gida-iframe-fitxa-show/gida-iframe-fitxa-show.module').then( m => m.GidaIframeFitxaShowPageModule)
  },
  {
    path: 'gida-gehitu-eskaintza',
    loadChildren: () => import('./gida-gehitu-eskaintza/gida-gehitu-eskaintza.module').then( m => m.GidaGehituEskaintzaPageModule)
  },
  {
    path: 'gida-gehitu-eskaintza-erantzuna',
    loadChildren: () => import('./gida-gehitu-eskaintza-erantzuna/gida-gehitu-eskaintza-erantzuna.module').then( m => m.GidaGehituEskaintzaErantzunaPageModule)
  },
    
];
 
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MemberRoutingModule { }









