

import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';

import { StorageService } from './core/storage.service';
//intelsat-2021
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationExtras } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';

import { PopZuzenekoaPage } from './members/pop-zuzenekoa/pop-zuzenekoa.page';
import { GiappService } from './core/giapp.service';
//import { M027Page } from './members/m027/m027.page';

import { BrowserService } from './core/browser.service';
import { GiappExtraService } from './core/giapp-extra.service';

import { App } from '@capacitor/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  //intelsat-2021
  custom_menu_pages: Array<{title: string, component: any}>;
  //intelsat-2023-gida-iframe-eskaintza-create-form
  gida_custom_menu_pages: Array<{title: string, component: any}>;
  rol:string="";
  zuzenekoa:any={id:0,embed_kode_url:""};
  nire_negozioak_api_row:any=null;
  giapp_user:any=null;
  custom_access_token="";

  constructor(
    private platform: Platform,
    //private splashScreen: SplashScreen,
    //private statusBar: StatusBar,
    //intelsat-2020
    private storage:StorageService,
    //intelsat-2021
    public giappService: GiappService,
    private router: Router,
    private route: ActivatedRoute,
    private menu_controller:MenuController,
    private zuzenekoa_popover:PopoverController,
    private iab: BrowserService,
    public giappExtraService: GiappExtraService,
  ) {

    
    this.pre_initialize_app();

    //intelsat-2023-gida-iframe-eskaintza-create-form
    //this.get_storage_giapp_user_async();
  }

  initializeApp() {
    this.platform.ready().then(() => {

      //this.statusBar.styleDefault();
      
      //intelsat-2021
      //OHARRA::::Bateria ez overlay egiteko
      //this.statusBar.overlaysWebView(false);
      //this.statusBar.overlaysWebView(true);

      //this.splashScreen.hide();

      //intelsat-2020
      this.set_storage_field("is_authState_subscribe_event_sortua",false);

      //this.service_get_nire_negozioak_custom_access_token_async("initializeApp");
    });
  }

  async set_storage_field(field,value){

    await this.storage.set(field,value);

  }

  //intelsat-2021
  define_custom_menu_pages(){
    let result=[];
    
    result.push({title: "Saioak", name:"saioak",icon_name:"play-circle-outline",url_page:["members/m024"]});
    result.push({title: "Ikusienak", name:"ikusienak",icon_name:"eye-outline",url_page:["members/m027"]});
    result.push({title: "Zuzenean", name:"zuzenean",icon_name:"play-outline",url_page:[]});
    result.push({title: "goiena.eus", name:"open_webgunea",icon_name:"globe-outline",url_page:[]});

    //intelsat-2021-giapp-capacitor
    let gida_icon_name="globe-outline";
    result.push({title: "Goiena 0 km gida", name:"open_gida",icon_name:gida_icon_name,url_page:[]});

    //intelsat-2023-gida-iframe
    /*let gida_iframe_icon_name="globe-outline";
    result.push({title: "Gida iframe", name:"open_gida_iframe",icon_name:gida_iframe_icon_name,url_page:["members/gida-iframe"]});*/
    
    if ( this.platform.is('android')){
      result.push({title: "Aplikazioa itxi", name:"itxi",icon_name:"close-outline",url_page:[]});
    }
    return result;  
  }

  async gotoMenuPage(custom_menu_item){
    const val=await this.storage.get('giapp_rol')
    //.then((val) => {
        this.rol=val;
        if(custom_menu_item.name=="zuzenean"){
          this.service_get_zuzenekoa_custom_access_token_async();
        }else if(custom_menu_item.name=="itxi"){
          App.exitApp();
          return 1;
        }else if(custom_menu_item.name=="open_webgunea"){    
          this.open_webgunea_browser("https://goiena.eus/");
        }else if(custom_menu_item.name=="open_gida"){    
          
          //intelsat-2021-giapp-capacitor
          //this.open_webgunea_browser("https://goiena.eus/gida");
          this.service_get_nire_negozioak_custom_access_token_async();

          //this.on_get_nire_negozioak(this.nire_negozioak_api_row,"");
        }else{
          
          let navigationExtras: NavigationExtras = {
            state: {
              rol:this.rol
            }
          };
          this.router.navigate(custom_menu_item.url_page, navigationExtras);
        }
        this.menu_controller.close();  
      //});
      return 1;

  }

  clickCloseMenu(){

    this.menu_controller.close();

  }

  zuzenekoa_popover_show(){
     this.zuzenekoa_popover.create({component:PopZuzenekoaPage,showBackdrop:false,cssClass: 'zuzenekoa-popover', componentProps:{"zuzenekoa":this.zuzenekoa} }).then((popoverElement)=>{
        popoverElement.present();
      })
  }

  async service_get_zuzenekoa_custom_access_token_async(){
    const val=await this.storage.get("custom_access_token")
    //.then((val) => {
        this.custom_access_token=val;
        this.service_get_zuzenekoa();
    //});
  }

  service_get_zuzenekoa(){
      let current_gvars=this.giappService.getGlobalVars();
      let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
      let row={"giapp_hedabideak_id":giapp_hedabideak_id};
      let zuzenekoa_row=JSON.stringify(row);
      //let zuzenekoa_post_data=JSON.stringify({"zuzenekoa":zuzenekoa_row});
      let zuzenekoa_post_data={"zuzenekoa":zuzenekoa_row};
      //let zuzenekoa_post_data=row;

      this.giappService.getZuzenekoa(zuzenekoa_post_data,this.custom_access_token).subscribe((r)=>{        
        
        this.zuzenekoa = r.zuzenekoa;
        
        console.log("getZuzenekoa="+JSON.stringify(this.zuzenekoa));
        
        this.zuzenekoa_popover_show();
      });
  }    
        
  open_webgunea_browser(url){      
    //let url="https://goiena.eus/";

    let options=this.giappExtraService.define_iab_options();
    console.log("options="+options);
    let browser = this.iab.open(url);

  }

  //intelsat-2021-giapp-capacitor
  async service_get_nire_negozioak_custom_access_token_async(action=""){
    const val=await this.storage.get("custom_access_token")
    //.then((val) => {
        this.custom_access_token=val;
        //console.log("custom_access_token nire_negozioak="+this.custom_access_token);
    
        this.service_get_nire_negozioak(action);
    //});
  }

  //intelsat-2021-giapp-capacitor
  service_get_nire_negozioak(action=""){

      //OHARRA::::"token_barra_t" parametroa Webguneko Api-ko CORS errorea zuzentzeko da

      this.giappService.getWebguneNireNegozioak(this.custom_access_token,"token_barra_t").subscribe((r)=>{        
        
        //console.log("getWebguneNireNegozioak="+JSON.stringify(r));
      
        this.on_get_nire_negozioak(r,action);
      });
  }

  //intelsat-2021-giapp-capacitor
  on_get_nire_negozioak(r,action=""){

    /*if(action=="initializeApp"){

      this.nire_negozioak_api_row=r;

      this.custom_menu_pages=this.define_custom_menu_pages();
      //intelsat-2023-gida-iframe-eskaintza-create-form
      this.gida_custom_menu_pages=this.define_gida_custom_menu_pages();

      return 1;
    }*/

    if(this.giappService.userrak_baditu_negozioak(r)){
    
      //if(r["count"]>1){

        //console.log("Negozio bat baino gehiago");

        this.goto_nire_negozioak(r);

        return 1;
      /*****
      }else{
        //console.log("Userrak baditu negozioak");
        let gidako_negozioa_kudeatu_url=this.giappService.get_gidako_negozioa_kudeatu_url(r);
        //console.log("gidako_negozioa_kudeatu_url="+gidako_negozioa_kudeatu_url);

        if(gidako_negozioa_kudeatu_url.length>0){

          this.open_webgunea_browser(gidako_negozioa_kudeatu_url);
        
          return 1;
        }
      }
      ****/  

    }else{

      this.open_webgunea_browser(this.giappService.negozio_gida_base_url);

      return 1;
    }

    this.open_webgunea_browser(this.giappService.negozio_gida_base_url);
  
    return 1;
  }

  async goto_nire_negozioak(nire_negozioak_collection){

    const val=await this.storage.get('giapp_rol')
    
    /*OHARRA::::agian komilla dobleekin heldu da storagetik eta komeni da "prepare_custom_access_token" funtzioan bezela komilla dobleak garbitzea
    bestela '"arrunta"'=='arrunta' eta batez ere '"kluba"'=='kluba' baldintza beteko
    */
    this.rol=val;
    
    //console.log("goto_nire_negozioak rol="+this.rol);

          let navigationExtras: NavigationExtras = {
            state: {
              rol:this.rol,
              nire_negozioak_collection:nire_negozioak_collection,
            }
          };
          this.router.navigate(['members/nire-negozioak'], navigationExtras);
        
        this.menu_controller.close();  
    
      return 1;
  }

  //intelsat-2023-gida-iframe-eskaintza-create-form
  clickGidaCloseMenu(){

    this.menu_controller.close();

  }

  //intelsat-2023-gida-iframe-eskaintza-create-form
  define_gida_custom_menu_pages(){
    let result=[];

    console.log("AppComponent this.giapp_user="+JSON.stringify(this.giapp_user));

    //if(this.giappService.userrak_baditu_negozioak(this.nire_negozioak_api_row)){
    
      result.push({title: "Gehitu eskaintza", name:"gehitu_eskaintza",icon_name:"add-circle-outline",url_page:["members/gida-gehitu-eskaintza"]});
    //}  

    //intelsat-2023-gida-iframe
    let gida_iframe_icon_name="globe-outline";
    let gida_iframe_title="Gida iframe";
    gida_iframe_title="Goiena 0 km gida";
    result.push({title: gida_iframe_title, name:"open_gida_iframe",icon_name:gida_iframe_icon_name,url_page:["members/gida-iframe"]});
    
    if ( this.platform.is('android')){
      result.push({title: "Aplikazioa itxi", name:"itxi",icon_name:"close-outline",url_page:[]});
    }
    return result;  
  }

  //intelsat-2023-gida-iframe-eskaintza-create-form
  async get_storage_giapp_user_async(){
    const val=await this.storage.getObject("giapp_user")
    //.then((val) => {
        this.giapp_user=val;
        this.pre_initialize_app();
    //});
  }

  pre_initialize_app(){

    //intelsat-2021
    this.custom_menu_pages=this.define_custom_menu_pages();
    //intelsat-2023-gida-iframe-eskaintza-create-form
    this.gida_custom_menu_pages=this.define_gida_custom_menu_pages();
    
    this.initializeApp();    
  }  
}
