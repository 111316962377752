
import { Injectable } from '@angular/core';
import { Observable, of, from, defer } from 'rxjs';
import { catchError, tap,switchMap, map } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
//import { StorageService } from './storage.service';
import { StorageService } from './storage.service';
import { HttpService } from './http.service';

import { Platform } from '@ionic/angular';
import { Share } from '@capacitor/share';

import { ToastController } from '@ionic/angular';



//eredu: https://www.positronx.io/create-ionic-angular-http-service/

export class Bideo {
  id: number;
  izena: string;
  gertaera_eguna: string;
  deskripzioa: string;
  poster: string;
  url: string;
  embed_kode_url: string;
}

export class BilaketaAttrib {
  testua: string;
  giapp_hedabideak_id:number=0;
  giapp_local_sareratu_term_id:number;
  giapp_gaiak_id:number;
  //api_limit:number=0;
  api_limit:number=100;
  //AND
  //herria_bilatzeko_modua:number=1;
  //OR
  herria_bilatzeko_modua:number=2;
}

export class GlobalVars {
  //Aiurri
  //"giapp_hedabideak_id":number=1;
  "hedabidea":string="goiena";
  "giapp_hedabideak_id":number=2;
  "sareko_id":string="GOI";
  "fetxa_cancel_text":string="Garbitu";
}

export class Zozketa {
  id:number;
  izenburua:string;
  zozketa_fetxa:string;
  zozketa_eguna:string;
  deskribapena:string;
  filepath_absolute:string;
  filepath_imagecache:string;
  filepath_imagecache_erdikoa:string;
  badauka_irudia: number;
}

export class Aldizkaria {
  id:number;
  izenburua:string;
  fetxa:string;
  url_show:string;
  aldizkaria_poster:string;
  url_pdf:string;
  aldizkaria_type_id:number;
}

export class Deskontua {
  id:number;
  izenburua:string;
  kontaktua:string;
  deskripzioa:string;
}

export class Egokitzapena {
  id:number;
  user_email:string="";
  giapp_mugikor_uid:number;
  deskripzioa:string;
  push_media:number;
  push_gida_komertziala:number;
  push_kluba:number;
  push_nahieran:number;
  created_at:string;
  updated_at:string;
}

export class Profila {
  id:number;
  giapp_mugikor_uid:number;
  izena:string;
  abizenak:string;
  emaila:string;
  pasahitza:string;
  kpostala:string;
  jdata:string;
  created_at:string;
  updated_at:string;
}

export class EginKide {
  id:number;
  giapp_mugikor_uid:number;
  izena:string;
  abizenak:string;
  dni:string;
  jdata:string;
  helbidea:string;
  herria:string;
  kpostala:string;
  is_pribatutasun_politika_onartua:number; 
  created_at:string;
  updated_at:string;
}

export class OptionsGaiak {
  gaiak:[];
}

export class OptionsHerriak {
  herriak:[];
}

export class GidaKomertziala {
  id:number;
  izena:string;
  deskripzioa:string;
  kontaktua:string;
  url:string;
  url_img:string;
  url_parent:string;
  created_at:string;
  updated_at:string;
}

//https://www.positronx.io/create-ionic-angular-http-service/

@Injectable({
  providedIn: 'root'
})
export class GiappService {

  negozio_gida_base_url="https://goiena.eus/gida/";

  //Aiurrikoak
  //bideo_zerrenda="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/${giapp_hedabideak_id}/bideoak"
  //OHARRA::::"fitxak" erabiltzea hobeto da, bideo, argazki galeriak eta audioak batera zerrendatzeko
  //bideo_zerrenda="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/${giapp_hedabideak_id}/fitxak"
  //Goienakoak
  //bideo_zerrenda="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/${giapp_hedabideak_id}/bideoak"
  //OHARRA::::"fitxak" erabiltzea hobeto da, bideo, argazki galeriak eta audioak batera zerrendatzeko
  bideo_zerrenda="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/${giapp_hedabideak_id}/fitxak"
  saio_zerrenda="https://cloud.tokimedia.eus/public/giapp/rest-api/saioak"
  //OHARRA::::"fitxak" erabiltzea hobeto da, bideo, argazki galeriak eta audioak batera zerrendatzeko
  //saio_bideo_zerrenda="https://cloud.tokimedia.eus/public/giapp/rest-api/saioa/${id}/bideoak"
  saio_bideo_zerrenda="https://cloud.tokimedia.eus/public/giapp/rest-api/saioa/${id}/fitxak"
  //bideo_bereiz="https://cloud.tokimedia.eus/public/giapp/rest-api/bideo/bereiz"
  bideo_bereiz="https://cloud.tokimedia.eus/public/giapp/rest-api/bideo/bereiz"
  //intelsat-2020
  //Aiurriko ikusienak
  //bideo_ikusienak_zerrenda="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/${giapp_hedabideak_id}/ikusienak"
  //Goienako ikusienak
  bideo_ikusienak_zerrenda="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/${giapp_hedabideak_id}/ikusienak"
  //Aiurriko zozketak
  //zozketa_zerrenda="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/${giapp_hedabideak_id}/zozketak"
  //Goienako zozketak
  zozketa_zerrenda="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/${giapp_hedabideak_id}/zozketak"  
  zozketan_parte_hartu_save_url="https://cloud.tokimedia.eus/public/giapp/rest-api/zozketa/parte-hartu"
  //Aiurriko saio zerrenda
  //saio_hedabide_zerrenda_url="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/${giapp_hedabideak_id}/saio/zerrenda"
  //Goienako saio zerrenda
  saio_hedabide_zerrenda_url="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/${giapp_hedabideak_id}/saio/zerrenda"
  //Goienako aldizkariak
  aldizkari_zerrenda="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/${giapp_hedabideak_id}/aldizkariak/${aldizkaria_type_id}"
  //Goienako deskontuak
  deskontu_zerrenda="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/${giapp_hedabideak_id}/deskontuak"
  egokitzapena_load_url="https://cloud.tokimedia.eus/public/giapp/rest-api/egokitzapena/load"
  egokitzapena_save_url="https://cloud.tokimedia.eus/public/giapp/rest-api/egokitzapena/save"
  gurekin_harremanetan_save_url="https://cloud.tokimedia.eus/public/giapp/rest-api/gurekin-harremanetan/save"
  profila_load_url="https://cloud.tokimedia.eus/public/giapp/rest-api/profila/load"
  profila_save_url="https://cloud.tokimedia.eus/public/giapp/rest-api/profila/save"
  egin_kide_load_url="https://cloud.tokimedia.eus/public/giapp/rest-api/egin-kide/load"
  egin_kide_save_url="https://cloud.tokimedia.eus/public/giapp/rest-api/egin-kide/save"
  options_gaiak_url="https://cloud.tokimedia.eus/public/giapp/rest-api/options/gaiak"
  options_herriak_url="https://cloud.tokimedia.eus/public/giapp/rest-api/options/herriak"
  options_all_url="https://cloud.tokimedia.eus/public/giapp/rest-api/options/all"
  //Goienako Gida Komertziala
  gida_komertziala_portada_old_url="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/${giapp_hedabideak_id}/gida-komertziala/portada-old"
  gida_komertziala_portada_url="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/${giapp_hedabideak_id}/gida-komertziala/portada"
  gida_komertziala_zerrenda_url="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/${giapp_hedabideak_id}/gida-komertziala"
  gida_komertziala_bilatu_bereiz_url="https://cloud.tokimedia.eus/public/giapp/rest-api/gida-komertziala/bereiz"
  zuzenekoa_load_url="https://cloud.tokimedia.eus/public/giapp/rest-api/zuzenekoa/load"
  kontua_sortu_save_url="https://cloud.tokimedia.eus/public/giapp/rest-api/kontua-sortu/eskaera-save"
  pasahitza_berreskuratu_save_url="https://cloud.tokimedia.eus/public/giapp/rest-api/pasahitza-berreskuratu/eskaera-save"
  //OHARRA::::Tokicloud-en gainean egiten den login-a
  //izena_eman_url="https://cloud.tokimedia.eus/public/giapp/rest-api/izena-eman"
  izena_eman_url="https://cloud.tokimedia.eus/public/api/auth/login"
  web_fitxa_load_url="https://cloud.tokimedia.eus/public/giapp/rest-api/web-fitxa/load"
  custom_access_token:string="";
  base_url_api_webgune="https://goiena.eus/api/3.0/";
  gida_negozio_zerrenda_url="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/gida/negozio/zerrenda";
  ezarpenak_load_url="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/ezarpenak/load";
  erlazionatutako_fitxak_url="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/erlazionatutako-fitxak";
  gida_komertzioak_bereiz_url="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/gida/komertzioak/bereiz";
  gida_produktuak_bereiz_url="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/gida/produktuak/bereiz";
  filtroa_gida_herriak_options_url="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/gida/filtroa/options/herriak";
  filtroa_gida_sailak_options_url="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/gida/filtroa/options/sailak";
  filtroa_gida_azpisailak_options_url="https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/gida/filtroa/options/azpisailak";
  user_push_token_save_url="https://cloud.tokimedia.eus/public/giapp/rest-api/giapp-user-push-save";

  //GiApp aktibo dauden Hedabideen zerrenda
  //https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/zerrenda
  //Zerrenada horretako "id"-ak erabiliz, hedabide baten saioen zerrenda atera daiteke,

  //id=1 Hedabidea Aiurri
  //https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/1/bideoak
  
  //Aiurri hedabidearen Giapp-eko saio zerrenda
  //https://cloud.tokimedia.eus/public/giapp/rest-api/hedabide/1/saio/zerrenda
  
  //Saio orokorren zerrenda (Hauek Tokicloud-en zuzenean definitzen dira eta artxibo lokaletatik saio hauetara bidal daitezke bideoak)
  //Beraz, hedabide ezberdinetako bideoak egon daitezke saio hauetan:
  //https://cloud.tokimedia.eus/public//giapp/rest-api/saio-orokorrak

  //Hedabide guztien saioak eta saio orokorrak
  //https://cloud.tokimedia.eus/public//giapp/rest-api/saioak
  //Gaiak
  //https://cloud.tokimedia.eus/public//giapp/rest-api/gaiak


  
  httpHeader = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  httpHeaderPost = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','Accept':'application/json' })
  };

  
  
  constructor(
    private storage: StorageService,
    private platform: Platform,
    private httpNative: HttpService,
    private http: HttpClient,
    public toastController: ToastController,
    
    
  ) {  }

  /*proba(){
    console.log("-------111111111-------------")

    //getBideoList(custom_access_token_in=""): Observable<Bideo[]> {

      let httpOptions=this.define_httpOptions("");
  
      let current_gvars:GlobalVars=this.getGlobalVars();
      let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
      let current_bideo_zerrenda_url:string=this.bideo_zerrenda.replace('${giapp_hedabideak_id}', giapp_hedabideak_id.toString());
  
      //console.log("current_bideo_zerrenda_url="+current_bideo_zerrenda_url);
      //return this.httpNative.get<Bideo[]>(this.set_remote_url(current_bideo_zerrenda_url),httpOptions)
      return this.httpNative.get(this.set_remote_url(current_bideo_zerrenda_url),httpOptions)
            .pipe(
              tap(Bideo => console.log('Bideo fetched!')),
              catchError(this.handleError<Bideo[]>('Get Bideo', []))
      );
  
    
    //}
  }*/
  
  getGlobalVars() {
    //console.log("-----aa---")
    let s=new GlobalVars();
    return s;
  }

  getBideoList(custom_access_token_in=""): Observable<Bideo[]> {
    this.custom_access_token=custom_access_token_in
    let httpOptions=this.define_httpOptions(custom_access_token_in);

    let current_gvars:GlobalVars=this.getGlobalVars();
    let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
    let current_bideo_zerrenda_url:string=this.bideo_zerrenda.replace('${giapp_hedabideak_id}', giapp_hedabideak_id.toString());

    //console.log("current_bideo_zerrenda_url="+current_bideo_zerrenda_url);

    //from( this.httpNative.get(this.set_remote_url(current_bideo_zerrenda_url),httpOptions))

    /*return this.httpNative.get(this.set_remote_url(current_bideo_zerrenda_url),httpOptions)
      .pipe(
        map(res => new (JSON.parse(res.data))),
        tap(Bideo => console.log('Bideo fetched!')),
        catchError(this.handleError<Bideo[]>('Get Bideo', []))
    );*/


    /*return this.httpNative.get<Bideo[]>(this.set_remote_url(current_bideo_zerrenda_url),httpOptions)
          .pipe(
            tap(Bideo => console.log('Bideo fetched!')),
            catchError(this.handleError<Bideo[]>('Get Bideo', []))
    );*/

    return this.http.get<Bideo[]>(this.set_remote_url(current_bideo_zerrenda_url),httpOptions)
          .pipe(
            tap(Bideo => console.log('Bideo fetched!')),
            catchError(this.handleError<Bideo[]>('Get Bideo', []))
    );
    

  }

  getSaioList(custom_access_token_in=""): Observable<Bideo[]> {
    //console.log("-----bb---")

    let httpOptions=this.define_httpOptions(custom_access_token_in);

    let current_gvars:GlobalVars=this.getGlobalVars();
    let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
    let current_saio_hedabide_zerrenda_url:string=this.saio_hedabide_zerrenda_url.replace('${giapp_hedabideak_id}', giapp_hedabideak_id.toString());

    //console.log('current_saio_hedabide_zerrenda_url='+current_saio_hedabide_zerrenda_url);

    return this.http.get<Bideo[]>(this.set_remote_url(current_saio_hedabide_zerrenda_url),httpOptions)
      .pipe(
        tap(Bideo => console.log('Saio fetched!')),
        catchError(this.handleError<Bideo[]>('Get Bideo', []))
      );
  }

  getSaioBideoList(saio,custom_access_token_in=""): Observable<Bideo[]> {

    let httpOptions=this.define_httpOptions(custom_access_token_in);
    
    let current_saio_bideo_zerrenda_url=this.saio_bideo_zerrenda.replace('${id}', saio.id);
    
    current_saio_bideo_zerrenda_url=this.set_remote_url(current_saio_bideo_zerrenda_url);

    console.log("current_saio_bideo_zerrenda_url="+current_saio_bideo_zerrenda_url);
    
    return this.http.get<Bideo[]>(current_saio_bideo_zerrenda_url,httpOptions)
      .pipe(
        tap(Bideo => console.log('Bideo fetched!')),
        catchError(this.handleError<Bideo[]>('Get Bideo', []))
      );
  }

  

  //getBideoBereiz( t1:any ): Observable<Bideo[]> {
  getBideoBereiz( t1:any,custom_access_token_in="" ){

    let httpOptions=this.define_httpOptions(custom_access_token_in);

    //console.log("tttttttt: " + JSON.stringify(t1) )

    let t= new BilaketaAttrib();

    let current_gvars:GlobalVars=this.getGlobalVars();
    t.giapp_hedabideak_id=current_gvars.giapp_hedabideak_id;
    //console.log("current_gvars.giapp_hedabideak_id="+current_gvars.giapp_hedabideak_id);
    
    //intelsat-2020, hau komentatu da eta ondorengoa jarri da
    if ( !("testua" in t1) )
      t.testua=t1['testua'];
    if ( !("giapp_hedabideak_id" in t1) )
      t.testua=t1['giapp_hedabideak_id'];
    if ( !("giapp_local_sareratu_term_id" in t1) )
      t.testua=t1['giapp_local_sareratu_term_id'];
    if ( !("giapp_gaiak_id" in t1) )
      t.testua=t1['giapp_gaiak_id'];
    if ( !("api_limit" in t1) )
      t.testua=t1['api_limit'];

    
    //t.testua="mozk";
    let httpHeader = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    console.log("this.bideo_bereiz: " + this.bideo_bereiz )
    console.log("t=" + JSON.stringify(t) )

    //this.bideo_bereiz="http://192.168.110.14/kk/post_test.php"

    /*var kk= this.http.post(this.bideo_bereiz,{"kk":1 }  ).subscribe(data => {
      console.log("data=" + data );
    })*/

    /***************************
    let header = {
      "Accept": 'application/json',
      "Content-Type": 'application/json'
    }
    var httpOptions={}
    
    httpOptions = { headers: new HttpHeaders(header)  };

    return  new Promise( (resolve, reject) => {
      this.http.post( this.bideo_bereiz, {"kk":1 }, httpOptions ).subscribe((rdata) => {
        console.log("1111:::: " + JSON.stringify(rdata))
        resolve(rdata);
      },
      err => {
        console.log("2222::::: " +JSON.stringify(err)  )
        resolve(err.error);
      })
    });
    ***************************/
    
    let bideo_bilatu=t1;
    bideo_bilatu.api_limit=t.api_limit;
    bideo_bilatu.giapp_hedabideak_id=t.giapp_hedabideak_id;
    bideo_bilatu.herria_bilatzeko_modua=t.herria_bilatzeko_modua;
    console.log("bideo_bilatu.is_fitxa_median_bilatu="+bideo_bilatu.is_fitxa_median_bilatu);

    
    let post_data_row={"bideo_bilatu":""};
    post_data_row.bideo_bilatu=JSON.stringify(bideo_bilatu);
    
    //intelsat-2021-giapp-capacitor
    //OHARRA::::kasu honetan dirudienez, ez da beharrezkoa JSON.stringify, ematen duenez JSON string moduan heldu da honera
    //let post_data=JSON.stringify(post_data_row);
    let post_data=post_data_row;

    //console.log("post_data: " + post_data)
    
    /************
    return this.http.post<Bideo[]>(this.bideo_bereiz , post_data, httpHeader )
    .pipe(
      catchError(this.handleError<Bideo[]>('getBideoBereiz'))
    );
    ***********/

    return this.http.post(this.set_remote_url(this.bideo_bereiz) , post_data, httpOptions )
    .pipe(
      catchError(this.handleError<any>('getBideoBereiz'))
    );




      /*kk.pipe(
        catchError(this.handleError<BilaketaAttrib>('Add Student'))
      );*/
    //console.log(typeof kk);
    //console.log("jjjjjj:::: " + JSON.stringify(kk));

    /*return this.http.post<Bideo[]>(this.bideo_bereiz , t, httpHeader )
      .pipe(
        catchError(this.handleError<Bideo[]>('Add Student'))
      );*/
  }

   







  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  //intelsat-2020
  getBideoIkusienakList(custom_access_token_in=""): Observable<Bideo[]> {
    //console.log("-----Service barruan getBideoIkusienakList---")

    let httpOptions=this.define_httpOptions(custom_access_token_in);

    let current_gvars:GlobalVars=this.getGlobalVars();
    let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
    let current_bideo_ikusienak_zerrenda_url:string=this.bideo_ikusienak_zerrenda.replace('${giapp_hedabideak_id}', giapp_hedabideak_id.toString());

    //console.log("current_bideo_ikusienak_zerrenda_url="+current_bideo_ikusienak_zerrenda_url);

    return this.http.get<Bideo[]>(this.set_remote_url(current_bideo_ikusienak_zerrenda_url),httpOptions)
      .pipe(
        tap(Bideo => console.log('Bideo fetched!')),
        catchError(this.handleError<Bideo[]>('Get Bideo', []))
      );
  }

  //intelsat-2020
  getZozketaList(custom_access_token_in=""): Observable<Zozketa[]> {
    //console.log("-----Service barruan getZozketaList---")

    let httpOptions=this.define_httpOptions(custom_access_token_in);
    
    let current_gvars:GlobalVars=this.getGlobalVars();
    let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
    let current_zozketa_zerrenda_url:string=this.zozketa_zerrenda.replace('${giapp_hedabideak_id}', giapp_hedabideak_id.toString());

    console.log("current_zozketa_zerrenda_url="+current_zozketa_zerrenda_url);

    return this.http.get<Zozketa[]>(this.set_remote_url(current_zozketa_zerrenda_url),httpOptions)
      .pipe(
        tap(Zozketa => console.log('Zozketa fetched!')),
        catchError(this.handleError<Zozketa[]>('Get Zozketa', []))
      );
  }

  postEredu(nora_in, datuak ): Observable<any> {
    
    let nora=this.set_remote_url(nora_in);

    return this.http.put(nora, datuak, this.httpHeader )
      .pipe(
        tap(_ => console.log("POST EGIN DA "+ nora)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  zozketanParteHartuSave(zozketa_post_data,custom_access_token_in=""): Observable<any> {

    //let httpOptions=this.httpHeaderPost;
    let httpOptions=this.define_httpOptions(custom_access_token_in);
    
    return this.http.post(this.set_remote_url(this.zozketan_parte_hartu_save_url), zozketa_post_data, httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ this.zozketan_parte_hartu_save_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  //intelsat-2020
  getAldizkariaList(aldizkaria_type_id,custom_access_token_in="",next_url=""): Observable<any> {
    
    //console.log("Service barruan getAldikariaList custom_access_token_in="+custom_access_token_in)

    let httpOptions=this.define_httpOptions(custom_access_token_in);

    //intelsat-2021-webgune-api
    let current_aldizkari_zerrenda:string="";

    let current_gvars:GlobalVars=this.getGlobalVars();
    let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
    current_aldizkari_zerrenda=this.aldizkari_zerrenda.replace('${giapp_hedabideak_id}', giapp_hedabideak_id.toString());
    current_aldizkari_zerrenda=current_aldizkari_zerrenda.replace('${aldizkaria_type_id}', aldizkaria_type_id.toString());
    
    current_aldizkari_zerrenda=this.set_remote_url(current_aldizkari_zerrenda);

    /****
    //intelsat-2021-webgune-api
    //current_aldizkari_zerrenda=this.set_remote_url(current_aldizkari_zerrenda);
    if(next_url.length>0){
      current_aldizkari_zerrenda=next_url;
      current_aldizkari_zerrenda=this.set_webgune_api_https(current_aldizkari_zerrenda);
    }else{
      //current_aldizkari_zerrenda=this.get_url_webgune_api('aldizkariak/');
      current_aldizkari_zerrenda=this.get_url_webgune_api('aldizkari_denak/');
    }*/

    console.log("current_aldizkari_zerrenda="+current_aldizkari_zerrenda);
    
    return this.http.get<any>(current_aldizkari_zerrenda,httpOptions)
      .pipe(
        tap(_ => console.log('Aldizkaria fetched!')),
        catchError(this.handleError<any[]>('Get Aldizkaria', []))
      );
  }

  //intelsat-2020
  getDeskontuaList(custom_access_token_in=""): Observable<Deskontua[]> {
    //console.log("-----Service barruan getDeskontuaList---")

    let httpOptions=this.define_httpOptions(custom_access_token_in);
    
    let current_gvars:GlobalVars=this.getGlobalVars();
    let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
    let current_deskontu_zerrenda:string=this.deskontu_zerrenda.replace('${giapp_hedabideak_id}', giapp_hedabideak_id.toString());

    //console.log("current_deskontu_zerrenda="+current_deskontu_zerrenda);

    return this.http.get<Deskontua[]>(this.set_remote_url(current_deskontu_zerrenda),httpOptions)
      .pipe(
        tap(Deskontua => console.log('Deskontua fetched!')),
        catchError(this.handleError<Deskontua[]>('Get Deskontua', []))
      );
  }

  //intelsat-2020
  getEgokitzapena(egokitzapena_post_data,custom_access_token_in=""): Observable<any> {

    //let httpOptions=this.httpHeaderPost;
    let httpOptions=this.define_httpOptions(custom_access_token_in);

    return this.http.post(this.set_remote_url(this.egokitzapena_load_url), egokitzapena_post_data, httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ this.egokitzapena_load_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  egokitzapenaSave(egokitzapena_post_data,custom_access_token_in=""): Observable<any> {

    //let httpOptions=this.httpHeaderPost;
    let httpOptions=this.define_httpOptions(custom_access_token_in);
    
    return this.http.post(this.set_remote_url(this.egokitzapena_save_url), egokitzapena_post_data, httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ this.egokitzapena_save_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  gurekinHarremanetanSendMail(gurekin_harremanetan_post_data,custom_access_token_in=""): Observable<any> {

    //let httpOptions=this.httpHeaderPost;
    let httpOptions=this.define_httpOptions(custom_access_token_in);

    let current_gurekin_harremanetan_save_url=this.set_remote_url(this.gurekin_harremanetan_save_url)
    
    return this.http.post(current_gurekin_harremanetan_save_url, gurekin_harremanetan_post_data,  httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ current_gurekin_harremanetan_save_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  //intelsat-2020
  getProfila(custom_access_token_in): Observable<any> {

    //console.log( "GET PROFILA custom_access_token_in=  "+ JSON.stringify(custom_access_token_in ));
    
    

    let httpOptions={};
    httpOptions=this.define_httpOptions(custom_access_token_in);
    let current_profila_url=this.get_url_webgune_api('user_data/');
    //console.log("+++get_profila_url" + current_profila_url )

    return this.httpNative.get( this.set_remote_url(current_profila_url),null,httpOptions )
    .pipe(
      map(res => { 
        //console.log("=====11111====getProfila===");
        //console.log("DDDD: " + JSON.stringify(res) )
        //console.log("DDDD1111: " + JSON.stringify(res.data) )
        return res.data
      }),
      tap(_ => console.log('getProfila fetched!')),
      catchError(this.handleError<any>('Get getProfila', []))
    );
  }

  profilaSave(profila_post_data,custom_access_token_in=""): Observable<any> {

    //let httpOptions=this.httpHeaderPost;
    let httpOptions=this.define_httpOptions(custom_access_token_in);

    let current_profila_save_url=this.get_url_webgune_api('user_edit/');
    console.log("Profila SAVEEEEE........" + current_profila_save_url )
    console.log("PRofila:::::  "+ JSON.stringify(profila_post_data))
    console.log("OPSSS:: " + JSON.stringify(httpOptions))
    
    return this.httpNative.post(current_profila_save_url, profila_post_data, httpOptions ).pipe(
      map(res => { 
        console.log("=====11111=profilaSave======");
        console.log("profilaSave: " + JSON.stringify(res) )
        console.log("profilaSave: " + res.data )
        return res.data
      }),
      tap(_ => console.log('getProfila fetched!')),
      catchError(this.handleError<any>('Get getProfila', []))
    );

    /*return this.http.post(current_profila_save_url, profila_post_data, httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ this.profila_save_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
    */
  }

  //intelsat-2020
  getEginKide(egin_kide_post_data,custom_access_token_in=""): Observable<any> {

    //let httpOptions=this.httpHeaderPost;
    let httpOptions=this.define_httpOptions(custom_access_token_in);

    return this.http.post(this.set_remote_url(this.egin_kide_load_url), egin_kide_post_data, httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ this.egin_kide_load_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  eginKideSave(egin_kide_post_data,custom_access_token_in=""): Observable<any> {

    //let httpOptions=this.httpHeaderPost;
    let httpOptions=this.define_httpOptions(custom_access_token_in);

    return this.http.post(this.set_remote_url(this.egin_kide_save_url), egin_kide_post_data, httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ this.egin_kide_save_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }
  
  getOptionsGaiak(options_gaiak_post_data): Observable<OptionsGaiak> {
    //console.log("-----Service barruan getOptionsGaiak---")
    return this.http.post(this.set_remote_url(this.options_gaiak_url), options_gaiak_post_data, this.httpHeaderPost )
      .pipe(
        tap(_ => console.log("POST EGIN DA=getOptionsGaiak")),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  getOptionsHerriak(options_herriak_post_data): Observable<OptionsHerriak> {
    console.log("-----Service barruan getOptionsHerriak---")
    return this.http.post(this.set_remote_url(this.options_herriak_url), options_herriak_post_data, this.httpHeaderPost )
      .pipe(
        tap(_ => console.log("POST EGIN DA=getOptionsHerriak")),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  getOptionsAll(custom_access_token_in=""): Observable<any> {
    //console.log("Service barruan getOptionsAll="+custom_access_token_in);
    
    let httpOptions={};

    //OHARRA::::Options-ak Tokicloud-etik kargatuko dira eta ez da tokena beharrik
    //httpOptions=this.define_httpOptions(custom_access_token_in);

    return this.http.get<any>(this.set_remote_url(this.options_all_url),httpOptions)
      .pipe(
        tap(_ => console.log('getOptionsAll fetched!')),
        catchError(this.handleError<any>('Get OptionsAll', []))
      );
  }

  set_remote_url(url_in){

    let remote_url:string=url_in;

    let is_tokicloud:number=1;

    //simulatzeko
    //is_tokicloud=0;    

    if(is_tokicloud){
    
      remote_url=remote_url.replace("proba1.tokimedia.eus","cloud.tokimedia.eus");
      remote_url=remote_url.replace("http://","https://");
    
    }else{
    
      remote_url=remote_url.replace("cloud.tokimedia.eus","proba1.tokimedia.eus");
    
    }  



    //OHARRA::::Passport Laravel, Codesyntax-ek Api-a garatu arte proba bezela zegoen
    //remote_url=remote_url.replace("public/giapp/rest-api/","public/api/auth/giapp/rest-api/");

    

    //console.log("remote_url="+remote_url);

    return remote_url;
  }

  getGidaKomertzialaList(custom_access_token_in=""): Observable<GidaKomertziala[]> {
    //console.log("-----Service barruan GidaKomertzialaList---")

    let httpOptions=this.define_httpOptions(custom_access_token_in);
    
    let current_gvars:GlobalVars=this.getGlobalVars();
    let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
    let current_gida_komertziala_zerrenda_url:string=this.gida_komertziala_zerrenda_url.replace('${giapp_hedabideak_id}', giapp_hedabideak_id.toString());

    //console.log("current_gida_komertziala_zerrenda_url="+current_gida_komertziala_zerrenda_url);


    return this.http.get<GidaKomertziala[]>(this.set_remote_url(current_gida_komertziala_zerrenda_url),httpOptions)
      .pipe(
        tap(GidaKomertziala => console.log('GidaKomertziala fetched!')),
        catchError(this.handleError<GidaKomertziala[]>('Get GidaKomertziala', []))
      );
  }

  gidaKomertzialaBereiz(gida_komertziala_bilatu_post_data,custom_access_token_in=""): Observable<any> {
    //console.log("-----Service barruan gidaKomertzialaBereiz---")
    
    //let httpOptions=this.httpHeaderPost;
    let httpOptions=this.define_httpOptions(custom_access_token_in);

    return this.http.post(this.set_remote_url(this.gida_komertziala_bilatu_bereiz_url), gida_komertziala_bilatu_post_data, httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA=gidaKomertzialaBereiz")),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  getZuzenekoa(zuzenekoa_post_data,custom_access_token_in=""): Observable<any> {

    //let httpOptions=this.httpHeaderPost;
    let httpOptions=this.define_httpOptions(custom_access_token_in);

    let current_zuzenekoa_load_url:string=this.set_remote_url(this.zuzenekoa_load_url);

    return this.http.post(current_zuzenekoa_load_url, zuzenekoa_post_data, httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ current_zuzenekoa_load_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  kontuaSortuSave(kontua_sortu_post_data,custom_access_token_in=""): Observable<any> {

    let httpOptions=this.httpHeaderPost;
    //let httpOptions=this.define_httpOptions(custom_access_token_in);

    let current_kontua_sortu_save_url=this.get_url_webgune_api('user_register/');

    //console.log("kontua_sortu_post_data: " + JSON.stringify(kontua_sortu_post_data));
    //console.log("custom_access_token_in: " + JSON.stringify(custom_access_token_in));
    //console.log("URLLL: " + current_kontua_sortu_save_url )

    //console.log("kontuaSortuSave httpHeaderPost="+JSON.stringify(this.httpHeaderPost));
    //console.log("kontuaSortuSave httpHeaderPost.header="+JSON.stringify(this.httpHeaderPost));

    return this.httpNative.post(current_kontua_sortu_save_url,kontua_sortu_post_data, this.httpHeaderPost )
      .pipe(
        //map(res => new (JSON.parse(res.data))),
        map(res => {

          //console.log("=====11111==kontuaSortuSave=====");
          
          //console.log("res: " + res );

          //console.log("DDDD: " + JSON.stringify(res) )
          //console.log("DDDD1111: " + res.data )
          
          //intelsat-2021-giapp-capacitor
          //OHARRA::::Json errorea dago, horregatik JSON.parse egin gabe funtzionatzen duela dirudi
          //return JSON.parse(res.data);
          return res.data;
        }),
        tap(_ => console.log("POST EGIN DA="+ this.kontua_sortu_save_url)),
        catchError(this.handleError<any>('POST Errorea'))
    );


    /*return this.http.post(current_kontua_sortu_save_url, kontua_sortu_post_data, this.httpHeaderPost )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ this.kontua_sortu_save_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );*/
  }

  pasahitzaBerreskuratuSave(pasahitza_berreskuratu_post_data): Observable<any> {

    return this.http.post(this.set_remote_url(this.pasahitza_berreskuratu_save_url), pasahitza_berreskuratu_post_data, this.httpHeaderPost )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ this.pasahitza_berreskuratu_save_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  //OHARRA::::Tokicloud-en gainean egiten den login-a
  izenaEman(izena_eman_post_data): Observable<any> {

    return this.http.post(this.set_remote_url(this.izena_eman_url), izena_eman_post_data, this.httpHeaderPost )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ this.izena_eman_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  get_row_field_value(row,field,is_numeric=false){

    if(row!=null && row[field]!=null){
      return row[field];
    }

    if(is_numeric){
      return 0;
    }

    return "";
  }

  get_giapp_rol(){
    return this.storage.getObject('giapp_rol')
  }
  
  set_storage_rol(target_class,field="rol",value_in="",pantalla=""){

    if(value_in!=null && value_in.length>0){

      return 1;

    }else{

      this.storage.get('giapp_rol').then((val) => {
        
        let current_rol=val;

        //if(current_rol!=null && current_rol.length>0){
        //  console.log("SET STORAGE ROL=pantalla="+pantalla+"="+current_rol)
        //  target_class[field]=current_rol;
        //}

      });

    }
  }

  shareBideo(bideo){
    let title=""
    let text=""
    let share_url=""

    if(bideo==null){
      return
    }

    title=bideo.izena;
    text+=bideo.izena+"\n"+bideo.deskripzioa;
    if (text.length > 400){
      text=text.substring(0,400)+" ...";
    }
    text+="\n"

    if ( bideo.web_url ){
      share_url=bideo.web_url
      this.shareMsg(title, text, share_url)
    }else{
      let current_gvars:GlobalVars=this.getGlobalVars();
      let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
      let web_fitxa_send_row=JSON.stringify({"giapp_hedabideak_id":giapp_hedabideak_id,embed_kode_url:bideo.embed_kode_url,internet_embed_kode_url:bideo.internet_embed_kode_url,"nid":bideo.id});
      let web_fitxa_post_data=JSON.stringify({"web_fitxa":web_fitxa_send_row});
      
      this.webFitxaLoad(web_fitxa_post_data).subscribe((r)=>{
        let is_embed_kodea=0;
        if(r.web_url!=null){
          share_url=r.web_url;
        }else{
          is_embed_kodea=1;
          share_url=bideo.embed_kode_url;
        }
        this.shareMsg(title, text, share_url)
      });
    }
    
  }

  shareMsg(title, text, share_url=null){
    console.log("title: " + title )
    console.log("text: " + text )
    console.log("share_url: " + share_url )
    let tsend={
      title: title,
      text: text,
      url: null,
      dialogTitle: 'Partekatzeko aplikazioa aukeratu ',
    }
    if (share_url) tsend.url=share_url;
    try{
      Share.share(tsend).then( (r)=>{
        console.log("Share with " + r)
      });
    } catch (error) {
      console.log("Share error...")
    }
  }

  webFitxaLoad(web_fitxa_post_data): Observable<any> {
    return this.http.post(this.set_remote_url(this.web_fitxa_load_url), web_fitxa_post_data, this.httpHeaderPost )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ this.web_fitxa_load_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  get_fitxa_herriaren_izena(bideo,herriak_max_in=2){

    let herriaren_izena:string="";

    if(bideo.herria_info!=null){

      if(bideo.herria_info.is_herri_multzokatuak!=null && bideo.herria_info.is_herri_multzokatuak==1){

        herriaren_izena=bideo.herria_info.herri_multzokatuak.name;

      }else if(bideo.herria_info.herriak!=null){

        let herriak_num:number=bideo.herria_info.herriak.length;
        let herriak_name_array:string[]=[];
        let herriak_max:number=herriak_max_in;

        //simulatzeko
        //herriak_max=1;

        //simulatzeko
        //herriak_num=0;

        if(herriak_num>0){

          if(herriak_num>herriak_max){
            herriak_num=herriak_max;
          }

          let i:number=0;
          let herria_row=null;

          for(i=0;i<herriak_num;i++){
            herria_row=bideo.herria_info.herriak[i];
            herriak_name_array.push(herria_row.name);
          }

          herriaren_izena=herriak_name_array.join(", ");

        }

      }

      if(herriaren_izena.length>0){
          return herriaren_izena;
      }      

      if(bideo.herria_info.probintzia!=null && bideo.herria_info.probintzia.name!=null){

        herriaren_izena=bideo.herria_info.probintzia.name;
        
      }

    }

    return herriaren_izena;
  }

  in_array(row_in,field,current_array){

    let num:number=current_array.length;
    //console.log("num="+num);

    if(num>0){

      let row:any;
      let i:number=0;
      let value=null;
      let field_len=field.length;

      for(i=0;i<num;i++){

        if(field_len==0){

          value=current_array[i];

          if(value==row_in){
            return true;
          }


        }else{

          row=current_array[i];

          //console.log(row[field]+"=="+row_in[field]);

          if(row[field]==row_in[field]){
            return true;
          }

        }  

      }

    }


    return false;
  }

  getGidaKomertzialaPortadaOld(custom_access_token_in="",post_data=null): Observable<any> {
    
    let httpOptions=this.define_httpOptions(custom_access_token_in);

    let current_gvars:GlobalVars=this.getGlobalVars();
    let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
    let current_gida_komertziala_portada_old_url:string=this.gida_komertziala_portada_old_url.replace('${giapp_hedabideak_id}', giapp_hedabideak_id.toString());

    //console.log("current_gida_komertziala_portada_old_url="+current_gida_komertziala_portada_old_url);

    //return this.http.get<any[]>(this.set_remote_url(current_gida_komertziala_portada_old_url),httpOptions)
    return this.http.post(this.set_remote_url(current_gida_komertziala_portada_old_url) , post_data, httpOptions )
      .pipe(
        tap(_ => console.log('GidaKomertziala portada fetched!')),
        catchError(this.handleError<any[]>('Get getGidaKomertzialaPortada', []))
      );
  }

  define_httpOptions(custom_access_token_in,enctype_in='',custom_token_type_in=''){

    let header = {};

    if(enctype_in!=null && enctype_in.length>0 && enctype_in=='multipart/form-data;'){

      //OHARRA::::Argazkia igotzeko, "Content type"='application/json' kendu behar da

      header={};
      
      
      //intelsat-2021-giapp-capacitor
      header["enctype"]=enctype_in;

      //header["Content-Type"]='application/json; charset=UTF-8';
      header["Accept"]='application/json';

    }else{


      header ={"Accept": 'application/json',
      "Content-Type": 'application/json'
      };


    }

    let httpOptions={};
    if(custom_access_token_in!=null && custom_access_token_in.length>0){
      
      //console.log("custom_token_type_in="+custom_token_type_in);

      if(custom_token_type_in=="token_barra_t"){
      
        //intelsat-2021-webgune-api
        //header['Authorization']='Bearer ' + custom_access_token_in;
        
        header['Authorization']='Token \t' + this.prepare_custom_access_token(custom_access_token_in);
        
      }else{  
        
        header["Authorization"]="Token " + custom_access_token_in;
      }

    }

    //httpOptions = { headers: new HttpHeaders(header)  };

    //console.log(type+"=hhhhhhhh2222  header: " + JSON.stringify(header));
    //return httpOptions;
    return header;
  }

  //intelsat-2021-webgune-api
  //OHARRA::::Benetako logina Codesyntax webgune Apiaren kontra egiten dena
  tokenAuth(post_data,betiko_logina_type=""): Observable<any> {

    let token_auth_url=this.get_url_webgune_api('token-auth/');
    //console.log("tokenAuth post_data:::: " + JSON.stringify(post_data))

    /*return this.http.post(token_auth_url, post_data, this.httpHeaderPost )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ token_auth_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
    */

    let operation:string='tokenAuth';

    if(betiko_logina_type=='betiko_logina'){

      operation=betiko_logina_type;      
    }
    
    return this.httpNative.post(token_auth_url,post_data )
      .pipe(
        map(res => { 
          return res.data
        }),
        tap( _ => console.log("tokenAuth POST EGIN DA="+ token_auth_url) ),
        catchError(this.tokenAuthError<Bideo[]>(operation, []))
      );

  }



  //intelsat-2021-webgune-api
  get_url_webgune_api(url_in){

    let url_webgune_api=this.base_url_api_webgune+url_in;

    return url_webgune_api;

  }

  //intelsat-2021-webgune-api
  get_next_url(pagina_row,next_url_type){

    let url='';

      if(this.badago_next(pagina_row)){

        if(next_url_type=='hurrengo_guztiak'){

          url=this.get_next_url_by_hurrengo_guztiak(pagina_row);

        }else{

          url=pagina_row.next;
      
        }
      }

      return url;

  }

  //intelsat-2021-webgune-api
  badago_next(pagina_row){

    if(pagina_row.next!=null){
     
      let url=pagina_row.next;   
      
      if(url.length>0){

        return true;
      
      }

    }
      
    return false;

    
  }

  //intelsat-2021-webgune-api
  get_next_url_by_hurrengo_guztiak(pagina_row){

    let url="";

    if(pagina_row.next!=null){

      if(pagina_row.next.length>0){

        url=pagina_row.next;

        let url_array=url.split("?");

        if(url_array.length>1){

          //console.log("url_array[0]="+url_array[0]);
          //console.log("url_array[1]="+url_array[1]);

          let query_array=url_array[1].split("&");

          let query_num:number=query_array.length;

          if(query_num>0){

            let i:number=0;

            for(i=0;i<query_num;i++){

              if(this.is_query_param_limit(query_array[i])){
                query_array[i]=this.set_query_param_limit(pagina_row,query_array[i]);
              }

            }

            url=url_array[0]+"?"+query_array.join("&");

          }

        }

      }  

    }


    return url;
  }

  is_query_param_limit(query_param){

    let query_param_array=query_param.split("=");

    if(query_param_array.length>1){

      if(query_param_array[0]=='limit'){
        
        return true;

      }

    }

    return false;
  }

  set_query_param_limit(pagina_row,query_param){

    let result:string="";

    let query_param_array=query_param.split("=");

    if(query_param_array.length>1){
      query_param_array[1]=pagina_row.count;
    }

    result=query_param_array.join("=");

    return result;
  }

  custom_merge_results(pagina_row,hurrengo_guztiak_row){

    let result=pagina_row.results;

    result=result.concat(hurrengo_guztiak_row.results);

    //console.log("merge num="+result.length);

    result=result.reverse();

    /*let api_limit=10;
    result=result.slice(0,api_limit);*/

    return result;
  }

  set_webgune_api_https(url_in){

    let url:string=url_in;

    url=url.replace("http://goiena.eus","https://goiena.eus");
    
    return url;

  }

  get_zerrenda_by_limit(result_in,api_limit_in=10){

    if(api_limit_in>0){
      let api_limit=api_limit_in;
      let result=result_in.slice(0,api_limit);

      return result;

    }  

    return result_in;
  }

  getWebguneGidaHerriakList(custom_access_token_in="",next_url=""): Observable<any> {
    
    console.log("getWebguneGidaHerriakList custom_access_token_in="+custom_access_token_in)

    let httpOptions=this.define_httpOptions(custom_access_token_in);

    //intelsat-2021-webgune-api
    let current_gida_herriak_zerrenda:string="";

    if(next_url.length>0){
      current_gida_herriak_zerrenda=next_url;
      current_gida_herriak_zerrenda=this.set_webgune_api_https(current_gida_herriak_zerrenda);
    }else{
      current_gida_herriak_zerrenda=this.get_url_webgune_api('herriak/');
    }

    //console.log("current_gida_herriak_zerrenda="+current_gida_herriak_zerrenda);

    return this.http.get<any>(current_gida_herriak_zerrenda,httpOptions)
      .pipe(
        tap(_ => console.log('Gida Herriak fetched!')),
        catchError(this.handleError<any[]>('getWebguneGidaHerriakList', []))
      );
  }

  getGidaNegozioZerrenda(post_data,custom_access_token_in=""): Observable<any> {

    let httpOptions=this.define_httpOptions(custom_access_token_in);

    let current_gida_negozio_zerrenda_url:string=this.gida_negozio_zerrenda_url;

    //console.log("current_gida_negozio_zerrenda_url="+current_gida_negozio_zerrenda_url);

    return this.http.post(this.set_remote_url(current_gida_negozio_zerrenda_url) , post_data, httpOptions )
      .pipe(
        tap(_ => console.log('getGidaNegozioZerrenda fetched!')),
        catchError(this.handleError<any[]>('Get getGidaNegozioZerrenda', []))
      );

  }

  get_negozioa_title(gida_negozioa,is_laburra=false,is_upper_case=false,is_herriarekin=false){

    let result:string="";

    if(gida_negozioa!=null){

      if(gida_negozioa.title!=null){
        
        result=gida_negozioa.title;

        if(is_upper_case){

          result=result.toUpperCase();

        }
      }  

    }

    if(is_herriarekin){

      let herria_name=this.get_negozioa_herria_name(gida_negozioa);

      if(herria_name.length>0){

        result+=" ("+herria_name+")";
      } 
    }  

    return result;
  }

  get_fitxa_izenburua_zerrendarako(bideo_izena){

    let result:string=bideo_izena;

    //OHARRA::::lehen slice, egiten zen 20-koa

    return result;
  }

  ezarpenakLoad(post_data,custom_access_token_in=""): Observable<any> {

    let httpOptions=this.define_httpOptions(custom_access_token_in);

    let current_ezarpenak_load_url:string=this.ezarpenak_load_url;

    console.log("current_ezarpenak_load_url="+current_ezarpenak_load_url);

    return this.http.post(this.set_remote_url(current_ezarpenak_load_url) , post_data, httpOptions )
      .pipe(
        tap(_ => console.log('Ezarpenak fetched!')),
        catchError(this.handleError<any[]>('Get ezarpenakLoad', []))
      );

  }

  get_webgune_api_result_row(r,field=""){

    if(field.length>0){

      //console.log("field="+field);

      if(r[field]!=null){

        //console.log("field not null="+field);

        if(r[field].length>0){
          return r[field][0];
        }

      }

    }else{

      if(r.count>0 && r.results!=null){

        return r.results[0];

      }

    }  

    return null;
  }

  get_profila_herria_value(profila){

    if(profila.get_towns!=null){

      let num:number=profila.get_towns.length;
      let herria_row:any=null;

      if(num==1){

        return profila.get_towns[0].name;

      }else{

        let i:number=0;

        for(i=0;i<num;i++){

          herria_row=profila.get_towns[i];

          if(this.is_herria_da(herria_row)){
            return herria_row.name;
          }

        }

      }

    }

    return "";
  }

  is_herria_da(herria_row){

    let not_herriak_array=["Debagoiena"];

    if(herria_row.name!=null){

      if(!this.in_array(herria_row.name,"",not_herriak_array)){
        return true;
      }

    }

    return false;
  }

  badauka_profileko_argazkia_info(profila){

    let badauka_profileko_argazkia_info={badauka_profileko_argazkia:0,img_url:""};

    if(profila.get_photo!=null){

      if(profila.get_photo.image!=null){

        if(profila.get_photo.image.length>0){
          
          badauka_profileko_argazkia_info.badauka_profileko_argazkia=1;
          badauka_profileko_argazkia_info.img_url=profila.get_photo.image;

          
        }

      }


    }

    return badauka_profileko_argazkia_info;
  }

  is_mugikorra_bertikalean_dago(){

    let is_mugikorra_bertikalean_dago:number=1;

    if(window.innerWidth>window.innerHeight){
    
      is_mugikorra_bertikalean_dago=0;
    
    }else{

      is_mugikorra_bertikalean_dago=1;

    }

    return is_mugikorra_bertikalean_dago;

  }

  get_herria_row(options_all,herria_name){

    let herria_row:any={probintzia_tid:0,probintzia_name:"",name:""};

    if(options_all!=null){

      
      if(options_all.herri_probintziak_assoc!=null){

        let probintzia_name_in:string="";
        let herriak_assoc:any[]=[];

        for(probintzia_name_in in options_all.herri_probintziak_assoc){

          if(options_all.herri_probintziak_assoc[probintzia_name_in].herriak!=null){

            herriak_assoc=options_all.herri_probintziak_assoc[probintzia_name_in].herriak;

            if(herriak_assoc[herria_name]!=null){


              herria_row=herriak_assoc[herria_name];
              herria_row.probintzia_tid=options_all.herri_probintziak_assoc[probintzia_name_in].probintzia_tid;
              herria_row.probintzia_name=probintzia_name_in;
              herria_row.name=herria_name;

            }

          }  

        }

      }

    }

    return herria_row;  

  }

  getErlazionatutakoFitxak(post_data_in,custom_access_token_in=""): Observable<any> {

    let httpOptions=this.define_httpOptions(custom_access_token_in);

    let post_data:any=post_data_in;
    let current_gvars:GlobalVars=this.getGlobalVars();
    let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
    let current_erlazionatutako_fitxak_url:string=this.erlazionatutako_fitxak_url;

    post_data.giapp_hedabideak_id=giapp_hedabideak_id;

    //console.log("current_erlazionatutako_fitxak_url="+current_erlazionatutako_fitxak_url);

   return this.http.post(this.set_remote_url(current_erlazionatutako_fitxak_url) , post_data, httpOptions )
          .pipe(
            tap(_ => console.log('getErlazionatutakoFitxak fetched!')),
            catchError(this.handleError<any[]>('Get getErlazionatutakoFitxak', []))
    );
    
  }

  getGidaKomertzialaPortada(post_data_in=null,custom_access_token_in=""): Observable<any> {
    
    let post_data=post_data_in;

    let httpOptions=this.define_httpOptions(custom_access_token_in);

    let current_gvars:GlobalVars=this.getGlobalVars();
    let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
    let current_gida_komertziala_portada_url:string=this.gida_komertziala_portada_url.replace('${giapp_hedabideak_id}', giapp_hedabideak_id.toString());
    
    if(post_data!=null){
      
      if(post_data["prepare_type"]==null){
        post_data["prepare_type"]="not_prepare_aplikatu";
        console.log("post_data[prepare_type]="+post_data["prepare_type"]);
      }
    }


    return this.http.post(this.set_remote_url(current_gida_komertziala_portada_url) , post_data, httpOptions )
      .pipe(
        tap(_ => console.log('GidaKomertziala portada fetched!')),
        catchError(this.handleError<any[]>('Get getGidaKomertzialaPortada', []))
      );
  }

  get_negozioa_herria_name(gida_negozioa,is_laburra=false){

    if(gida_negozioa.herria!=null && gida_negozioa.herria.name!=null){

      return gida_negozioa.herria.name;

    }

    return "";

  }

  get_negozioa_azpisaila_name(gida_negozioa){

    if(gida_negozioa.azpisailak!=null){

      let num=gida_negozioa.azpisailak.length;

      if(num>0){

        let azpisaila_row=gida_negozioa.azpisailak[0];
        
        return azpisaila_row.title;
      
      }

    }

    return "";

  }

  get_produktua_title(gida_produktua){

    return this.get_negozioa_title(gida_produktua);

  }

  get_produktua_negozioa_title(gida_produktua){

    if(gida_produktua!=null){

      if(gida_produktua.negozioa!=null){

        let negozioa_title:string=this.get_negozioa_title(gida_produktua.negozioa);

        negozioa_title=negozioa_title.toUpperCase();

        return negozioa_title;
      }

    }  

    return "";
  }

  is_nabarmendua(row){

    //console.log("row.nabarmendua="+row.nabarmendua);
    if(row.nabarmendua!=null && row.nabarmendua==1){

      return 1;

    }

    return 0;
  }

  getGidaKomertzioakBereiz(post_data=null,custom_access_token_in=""){
    let httpOptions=this.define_httpOptions(custom_access_token_in);
    let current_gida_komertzioak_bereiz_url:string=this.gida_komertzioak_bereiz_url;

    return this.http.post(this.set_remote_url(current_gida_komertzioak_bereiz_url) , post_data, httpOptions )
      .pipe(
        tap(_ => console.log('GidaKomertziala portada fetched!  ')),
        catchError(this.handleError<any[]>('Get getGidaKomertzialaPortada', []))
      );
  }

  getGidaProduktuakBereiz(post_data=null,custom_access_token_in=""){
    let httpOptions=this.define_httpOptions(custom_access_token_in);
    let current_gida_produktuak_bereiz_url:string=this.gida_produktuak_bereiz_url;

    return this.http.post(this.set_remote_url(current_gida_produktuak_bereiz_url) , post_data, httpOptions )
      .pipe(
        tap(_ => console.log('GidaKomertziala portada fetched!')),
        catchError(this.handleError<any[]>('Get getGidaKomertzialaPortada', []))
      );
  }

  getFiltroaGidaHerriakOptions(post_data=null,custom_access_token_in=""): Observable<any> {
    let httpOptions=this.define_httpOptions(custom_access_token_in);
    let current_filtroa_gida_herriak_options_url:string=this.filtroa_gida_herriak_options_url;

    return this.http.post(this.set_remote_url(current_filtroa_gida_herriak_options_url) , post_data, httpOptions )
      .pipe(
        tap(_ => console.log('getFiltroaGidaHerriakOptions fetched!')),
        catchError(this.handleError<any[]>('Get getFiltroaGidaHerriakOptions', []))
      );
  }

  getFiltroaGidasailakOptions(post_data=null,custom_access_token_in=""): Observable<any> {
    let httpOptions=this.define_httpOptions(custom_access_token_in);
    let current_filtroa_gida_sailak_options_url:string=this.filtroa_gida_sailak_options_url;

    return this.http.post(this.set_remote_url(current_filtroa_gida_sailak_options_url) , post_data, httpOptions )
      .pipe(
        tap(_ => console.log('getFiltroaGidasailakOptions fetched!')),
        catchError(this.handleError<any[]>('Get getFiltroaGidasailakOptions', []))
      );
  }

  getFiltroaGidaAzpiSailakOptions(post_data=null,custom_access_token_in=""): Observable<any> {
    let httpOptions=this.define_httpOptions(custom_access_token_in);
    let current_filtroa_gida_azpisailak_options_url:string=this.filtroa_gida_azpisailak_options_url;

    return this.http.post(this.set_remote_url(current_filtroa_gida_azpisailak_options_url) , post_data, httpOptions )
      .pipe(
        tap(_ => console.log('getFiltroaGidaAzpiSailakOptions fetched!')),
        catchError(this.handleError<any[]>('Get getFiltroaGidaAzpiSailakOptions', []))
      );
  }

  get_zerbitzua_title(gida_zerbitzua){
    return this.get_negozioa_title(gida_zerbitzua);
  }

  get_zerbitzua_negozioa_title(gida_zerbitzua){
    return this.get_produktua_negozioa_title(gida_zerbitzua);
  }
  
  get_bilaketa_bat_ere_ez_mezua(){
    let result:string="Sentitzen dugu! Ez dugu aukera horiekin ezer aurkitu...";
    return result;
  }

  //intelsat-2021-giapp-capacitor
  userPhotoUpload(argazkia_file_in,custom_access_token_in,custom_token_type_in=""){
    let argazkia_file=argazkia_file_in;
    let httpOptions={};

    //console.log("argazkia_file="+argazkia_file);

    //intelsat-2021-giapp-capacitor
    httpOptions=this.define_httpOptions(custom_access_token_in,'multipart/form-data;',custom_token_type_in);

    let user_photo_upload_url:string=this.get_url_webgune_api('user_photo/');
    let form_data = new FormData();
    form_data.append('file', argazkia_file);

    console.log("form_data="+form_data.get('file'));

    //intelsat-2021-giapp-capacitor
    return this.httpNative.post(user_photo_upload_url, form_data, httpOptions,'multipart/form-data;' ).pipe(
      map(res => { 
    
        return res.data
      }),
      tap(_ => console.log('userPhotoUpload fetched!')),
      catchError(this.handleError<any>('userPhotoUpload error', []))
    );


    /***********
    return this.http.post(user_photo_upload_url,form_data,httpOptions)
      .pipe(
        tap(_ => console.log('userPhotoUpload fetched!')),
        catchError(this.handleError<any[]>('userPhotoUpload error', []))
      ); 
    ************/  
  }

  //intelsat-2021-giapp-capacitor
  getBazkidetza(custom_access_token_in,custom_token_type_in=''): Observable<any> {
    let httpOptions={};

    //intelsat-2021-giapp-capacitor
    httpOptions=this.define_httpOptions(custom_access_token_in,'',custom_token_type_in);
    
    let current_bazkideta_url=this.get_url_webgune_api('bazkidetza/');

    //intelsat-2021-giapp-capacitor
    return this.httpNative.get( this.set_remote_url(current_bazkideta_url),null,httpOptions )
    .pipe(
      map(res => { 
        
        return res.data
      }),
      tap(_ => console.log('getBazkidetza fetched!')),
      catchError(this.handleError<any>('getBazkidetza', []))
    );

    /********
    return this.http.get<any>(current_bazkideta_url,httpOptions)
      .pipe(
        tap(_ => console.log('getBazkidetza fetched!')),
        catchError(this.handleError<any>('Get getBazkidetza', []))
      );
    ********/  
  }

  kontuaSortuExtraSave(kontua_sortu_extra_post_data,custom_access_token_in=""): Observable<any> {
    let httpOptions=this.httpHeaderPost;
    let current_kontua_sortu_extra_save_url:string=this.set_remote_url(this.kontua_sortu_save_url);

    return this.http.post(current_kontua_sortu_extra_save_url, kontua_sortu_extra_post_data, this.httpHeaderPost )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ this.kontua_sortu_save_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  sendBazkidetu(post_data,custom_access_token_in=""): Observable<any> {
    let httpOptions=this.httpHeaderPost;
    let current_bazkidetu_url=this.get_url_webgune_api('bazkidetu/');

    return this.http.post(current_bazkidetu_url, post_data, this.httpHeaderPost )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ current_bazkidetu_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  userPushTokenSave(post_data,custom_access_token_in=""): Observable<any> {
    let httpOptions=this.httpHeaderPost;
    let current_user_push_token_save_url=this.set_remote_url(this.user_push_token_save_url)

    return this.http.post(current_user_push_token_save_url, post_data, httpOptions )
      .pipe(
        tap(_ => console.log("POST EGIN DA="+ current_user_push_token_save_url)),
        catchError(this.handleError<any>('POST Errorea'))
      );
  }

  prepare_download_url(download_url_in,rol_in){
    let download_url=download_url_in;
    download_url=this.add_ur_markarekin_by_rol(download_url,rol_in);
    return download_url;
  }
  
  add_ur_markarekin_by_rol(download_url_in,rol_in){
    let download_url=download_url_in;
    if(rol_in=="kluba"){
      //simulatzen
      /*if(!this.badauka_url_parametrorik(download_url)){
        download_url=download_url+"?proba=1";
      }*/

      //OHARRA::::ur_markarekin=2, ur marka kentzeko da, defektuz ur markarekin jaitsiko baititugu

      let konp="ur_markarekin=2";
      let pos=download_url.indexOf(konp);

      if(pos>=0){

        return download_url;

      }else{

        if(this.badauka_url_parametrorik(download_url)){

          download_url=download_url+"&";

        }else{

          download_url=download_url+"?";

        }  

        download_url=download_url+"ur_markarekin=2";

        //console.log("add_ur_markarekin_by_rol="+download_url);

      }

    }
    
    return download_url;

  }

  badauka_url_parametrorik(download_url){

      let konp="?";
      let pos=download_url.indexOf(konp);

      if(pos>=0){

        return true;

      }

      return false;
  }

  prepare_embed_kode_url(embed_kode_url_in,rol_in){

    let embed_kode_url=this.prepare_download_url(embed_kode_url_in,rol_in);

    return embed_kode_url;

  }

  get_storage_field_name_by_profila(profila_in,gordetako_aurrizkia){

    let storage_field_name=gordetako_aurrizkia;

    if(profila_in && profila_in["username"]!=null && profila_in["username"].length>0){

      storage_field_name=storage_field_name+"_"+profila_in["username"];

    }

    //console.log("storage_field_name="+storage_field_name);

    return storage_field_name;

  }

  is_direct_url_share(bideo,url_in){

    if(bideo==null){

      return true;

    }

    return false;
  }

  //intelsat-2021-giapp-capacitor
  getWebguneNireNegozioak(custom_access_token_in,custom_token_type): Observable<any> {

    let httpOptions={};
    httpOptions=this.define_httpOptions(custom_access_token_in,"",custom_token_type);
    let nire_negozioak_url=this.get_url_webgune_api('nire-negozioak/');
    
    
    return this.httpNative.get( this.set_remote_url(nire_negozioak_url),null,httpOptions )
    .pipe(
      map(res => { 
        
        return res.data
      }),
      tap(_ => console.log('getWebguneNireNegozioak fetched!')),
      catchError(this.handleError<any>('getWebguneNireNegozioak', []))
    );
  }

  //intelsat-2021-giapp-capacitor
  userrak_baditu_negozioak(r){

    if(r!=null){
      
      if(r["count"]!=null){

        //console.log("count="+r["count"]);

        if(r["count"]>0){

          return true;
        }

      }
    }

    return false;
  }

  //intelsat-2021-giapp-capacitor
  prepare_custom_access_token(custom_access_token_in){

    let result_token=custom_access_token_in.replaceAll(/['"]+/g, '');

    return result_token;
  }

  //intelsat-2021-giapp-capacitor
  get_gidako_negozioa_kudeatu_url(r,kont_in=0){

    let gidako_negozioa_kudeatu_url="";

    if(this.userrak_baditu_negozioak(r)){

      //console.log("nire negozioak array: " + JSON.stringify(r.results[0]) )

      //OHARRA::::Momentuz lehenengoa hartzen dugu
      let nire_negozioa_row=r.results[kont_in];
      let slug="";

      if(nire_negozioa_row["slug"]!=null){

        slug=nire_negozioa_row["slug"];

        if(slug.length>0){

          gidako_negozioa_kudeatu_url=this.negozio_gida_base_url+slug+"/kudeatu";
        }

      }

    }

    return gidako_negozioa_kudeatu_url;
  }
  
  private tokenAuthError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      /****
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      *****/

      console.log("tokenAuth errorea operation="+operation);

      if(operation=='betiko_logina'){

        let duration_toast:number=5000;

        let login_okerra_msg:string="";
        
        //login_okerra_msg="Login Okerra: Erabiltzailea edo pasahitza okerrak izan daitezke"
        
        //OHARRA::::Esaldi hau goiena.eus webguneko login sistematik hartutakoa da
        login_okerra_msg="Mesedez idatzi helbide elektronikoa eta pasahitz egokiak. Maiskula eta minuskulak ondo bereiztu."; 

        this.presentToast(login_okerra_msg,duration_toast);
      }  

      return of(result as T);
    };
  }

  async presentToast(msg,duration_toast) {
    const toast = await this.toastController.create({
      message: msg,
      duration: duration_toast
    });
    toast.present();
  }

}