import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from   './core/auth/auth.guard';

import { MemberRoutingModule } from './members/member-routing.module';
import { PublicRoutingModule } from './public/public-routing.module';


const routes: Routes = [
  { path: '', redirectTo: 'public/p001-hasiera', pathMatch: 'full' },
  { 
    path: 'members', 
    //canActivate: [AuthGuard],
    loadChildren : ()=>MemberRoutingModule 
  },
  { 
    path: 'public',
      loadChildren : ()=>PublicRoutingModule
  },
  
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
