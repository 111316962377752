import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: 'p001',
    loadChildren: () => import('./p001/p001.module').then( m => m.P001PageModule)
  },
  {
    path: 'p002',
    loadChildren: () => import('./p002/p002.module').then( m => m.P002PageModule)
  },
  {
    path: 'p003',
    loadChildren: () => import('./p003/p003.module').then( m => m.P003PageModule)
  },
  {
    path: 'p004',
    loadChildren: () => import('./p004/p004.module').then( m => m.P004PageModule)
  },
  {
    path: 'p005',
    loadChildren: () => import('./p005/p005.module').then( m => m.P005PageModule)
  },
  {
    path: 'p006',
    loadChildren: () => import('./p006/p006.module').then( m => m.P006PageModule)
  },
  {
    path: 'p045',
    loadChildren: () => import('./p045/p045.module').then( m => m.P045PageModule)
  },
  {
    path: 'p002-pasahitza-ahaztu-zait',
    loadChildren: () => import('./p002-pasahitza-ahaztu-zait/p002-pasahitza-ahaztu-zait.module').then( m => m.P002PasahitzaAhaztuZaitPageModule)
  },
  {
    path: 'p004-pribatutasun-politika',
    loadChildren: () => import('./p004-pribatutasun-politika/p004-pribatutasun-politika.module').then( m => m.P004PribatutasunPolitikaPageModule)
  },
  {
    path: 'p001-hasiera',
    loadChildren: () => import('./p001-hasiera/p001-hasiera.module').then( m => m.P001HasieraPageModule)
  },
  {
    path: 'p004-lege-oharra',
    loadChildren: () => import('./p004-lege-oharra/p004-lege-oharra.module').then( m => m.P004LegeOharraPageModule)
  },
  {
    path: 'p045-kontua-edit-erantzuna',
    loadChildren: () => import('./p045-kontua-edit-erantzuna/p045-kontua-edit-erantzuna.module').then( m => m.P045KontuaEditErantzunaPageModule)
  },
  {
    path: 'p004-pasahitza-ez-da-zuzena-modal',
    loadChildren: () => import('./p004-pasahitza-ez-da-zuzena-modal/p004-pasahitza-ez-da-zuzena-modal.module').then( m => m.P004PasahitzaEzDaZuzenaModalPageModule)
  },
  {
    path: 'p007',
    loadChildren: () => import('./p007/p007.module').then( m => m.P007PageModule)
  }

];
 
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule { }






















