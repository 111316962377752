import { Injectable } from '@angular/core';
import { Http } from '@capacitor-community/http';
import { Directory } from '@capacitor/filesystem';
import { Observable, from, defer } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor() { }

  get(url, params=null, headers=null) {
    var options = {
      url: url,
      method: 'GET',
      params: null,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
    }
    if (params){
      options['params']=params;
    }
    if ( headers ){
      options['headers']=headers;
    }
    
    //intelsat-2021-giapp-capacitor
    //OHARRA::::Bestela userraren tokena kontsolan erakusten da
    //console.log("OPPPPP:  " + JSON.stringify(options))
    
    return from(  Http.request(options).then() )

    
    /*.then( (httpResponse)=>{
      console.log("httpResponse::GET::::: " + httpResponse )
    });*/
    

  };

  /*async getONA(url, params=null, headers=null) {
    var options = {
      url: url
    }
    if (params){
      options['params']=params;
    }
    if ( headers ){
      options['headers']=headers;
    }

    await Http.get(options).then( (httpResponse)=>{
      console.log("httpResponse::GET::::: " + httpResponse )
    });

  };*/
  
  post(url, data=null, headers=null,enctype_in='') {

    //console.log("POST headers:::: " + JSON.stringify(headers))

    var options= {
          method: 'POST',
          url: url,
          headers: {},
          data: null
    };

    //intelsat-2021-giapp-capacitor      
    if(enctype_in!=null && enctype_in.length>0 && enctype_in=='multipart/form-data;'){

      options['headers']={};
      options['headers']['Accept']="application/json";
      options['headers']['Content-Type']=enctype_in;

      /*if ( headers ){
        if(headers['Authorization']!=null){
          options['headers']['Authorization']=headers['Authorization'];
        }
      }*/

      //console.log("data:::: " + data.get('file'));

   
    }else{ 

         options= {
          method: 'POST',
          url: url,
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
          },
          data: null
        }

    }

    if ( headers ){
        if(headers['Authorization']!=null){
          options['headers']['Authorization']=headers['Authorization'];
        }
    }    

    if (data){

      options['data']=data;
    }
    
    //intelsat-2021-giapp-capacitor
    /*OHARRA::::hau komentatu egin da, batzutan okerreko header-a etortzen delako
    POST headers:::: {"headers":{"normalizedNames":{},"lazyUpdate":null,"lazyInit":null,"headers":{}}}
    */

    if ( headers ){
      //console.log("KONTUZZZZX HEADER!!!!   " + JSON.stringify(headers))
      
      //intelsat-2021-giapp-capacitor
      //OHARRA::::Komentatu egin da
      //options['headers']= headers;
    }
 
    console.log("KONTUZZZZX HEADER!!!!   " + JSON.stringify(options));  

    /*await Http.post(options).then( (httpResponse)=>{
      console.log("httpResponse::POST::::: " + httpResponse )
    });*/
    
    //console.log("POST options:::: " + JSON.stringify(options))
    
    return from(  Http.request(options).then() )

  
  };
  
  //this.nativeHTTP.downloadFile(download_url_in, {}, {}, filePath + "1_" + basename ).then(response => {
  
  downloadFile(url, localFile, localDir ) {
    
    const name=localFile.replace(/.*\//, '');
    const toDir=localDir+"/"+localFile.match(/.*\//);

    
    const options = {
      method: 'GET',
      url: url,
      //headers: {},
      //filePath: localFile,
      //fileDirectory: localDir,
      filePath: name,
      fileDirectory: Directory.Data,
      //connectTimeout: 5000,
      //readTimeout:1000
    };

    const extension = url.substring(url.lastIndexOf('.') + 1, url.length) || url;
    if (  extension.length  > 5  ){
      //options.url=url+"/"
      //options.method='POST'
    }
    //console.log("url:::: " + url )
      
    // Writes to local filesystem
    //const response: HttpDownloadFileResult = await Http.downloadFile(options);
    //console.log("JJJDOWNLOADDDD:::: " + JSON.stringify(options))
    //return from(  Http.downloadFile(options).then() )

    return from(  Http.downloadFile(options).then() )

    /*Http.downloadFile(options).then( (httpResponse)=>{
      console.log("httpResponse::downloadFile::::: " + httpResponse )
    });*/
      
    
  
  };
  
  /*const uploadFile = async () => {
    const options = {
      url: 'https://example.com/path/to/upload.pdf',
      name: 'myFile',
      filePath: 'document.pdf',
      fileDirectory: FilesystemDirectory.Downloads,
    };
  
    const response: HttpUploadFileResult = await Http.uploadFile();
  };*/
  
}






